<!--
 * @Description: description
 * @Author: booshaw
 * @Github: github
 * @Date: 2022-06-01 17:44:01
 * @LastEditors: booshaw
 * @LastEditTime: 2022-06-10 19:12:35
-->
<template>
  <span class="price-box" :style="{textDecoration: textDecoration, color: color, fontSize: fontSize, fontWeight: fontWeight}"><span style="font-size:16px">¥</span>{{value}}</span>
</template>
<script>
export default {
  props: {
    value: {
      type: Number | String,
      default: 0
    },
    color: {
      type: String,
      default: '#e33233'
    },
    textDecoration: {
      type: String,
      default: ''
    },
    fontSize: {
      type: String,
      default: '26px'
    },
    fontWeight: {
      type: Number,
      default: 400
    }
  },
  computed: {
    valueComputed() {
      return  this.value.toLocaleString('zh', { style: 'currency', currency: 'CNY' })
    }
  }
}
</script>
<style lang="less">
.price-box {
  font-weight: bold;
}
</style>