<template>
  <nav :key="$store.state.showTabar" class="nav-wrapper">
    <div class="nav tanxin content-wrapper flex-between">
      <h1>
        <img
          class="cup"
          @click="$router.push('/home')"
          src="../assets/images/home/logo.png"
          alt
        />
      </h1>
      <div class="flex-between">
        <CustomSearch
          ref="child"
          :serchQuery="serchQuery"
          v-if="isSearch"
        ></CustomSearch>
        <div @click="$router.push('/shoppingCart')" class="myCart">
          <img
            style="margin-right:5px"
            src="@/assets/images/util/cart.png"
            alt=""
          />
          <el-badge
            style="margin: 0 4px"
            :value="$store.state.ProductNum"
            class="item"
            >我的购物车</el-badge
          >
          <!-- <div class="cartbage"  >{{$store.state.ProductNum}}</div> -->
        </div>
        <div class="download">
          <img
            src="@/assets/images/header/mobileDownloadUrl.png"
            style="width: 78px; height: 78px"
          />
          <div class="ma">扫码下载</div>
        </div>
      </div>

      <div v-if="isquick" class="quickly">
        <el-steps :space="200" :active="2" align-center finish-status="success">
          <el-step title="上传商品文件"></el-step>
          <el-step title="确认商品并加入购物车"></el-step>
        </el-steps>
      </div>
      <div v-if="iscart" class="quickly">
        <el-steps :space="200" :active="2" align-center finish-status="success">
          <el-step title="确认商品信息"></el-step>
          <el-step title="确认订单信息"></el-step>
          <el-step title="完成支付"></el-step>
        </el-steps>
      </div>
    </div>
    <div v-if="showTabar" class="tabar content-wrapper">
      <ul class="df">
        <div
          class="li"
          style="width: 200px; position: relative;color:#fff"
          @click="change(1)"
          :class="active == 1 ? 'active  cup' : 'active cup'"
        >
          全部商品种类
          <div
            :style="
              $store.state.showTabar ? '  display:block;' : '  display:none;'
            "
          >
            <div class="hdm">
              <ul>
                <li
                  v-for="(item, index) in productTree"
                  :key="index"
                  class="lii"
                >
                  <div class="navigation-item">
                    <div>
                      <img
                        class="icon"
                        :src="
                          require('@/assets/images/sticky/f' + index + '.png')
                        "
                        alt
                      />
                      {{ item.text }}
                    </div>
                    <i class="el-icon-arrow-right">&#xe501;</i>
                  </div>
                  <div class="hdm_hidden">
                    <ul>
                      <li
                        v-for="(it, id) in item.children"
                        :key="id"
                        class="df"
                      >
                        <div class="left">
                          <div class="span">{{ it.text }}</div>
                          <i class="el-icon-arrow-right"></i>
                        </div>
                        <div class="right">
                          <div
                            @click.stop="goToGoods(childIt)"
                            v-for="(childIt, childId) in it.children"
                            :key="childId"
                            class="ac"
                          >
                            {{ childIt.text }}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div v-if="!iscart" class="df">
          <div
            v-for="(item, index) in headerList"
            :key="index"
            class="li"
            @click="change(item.active)"
            :class="active == item.active ? 'active2 cup' : 'cup'"
          >
            {{ item.name }}
            <!-- <img v-if="item.active==='tehui'" src="../assets/images/home/hot.gif" alt="" style="width:28px;height:28px;"> -->
          </div>
        </div>
      </ul>
    </div>
  </nav>
</template>
<script>
import { categorysTree } from "@/request/api";
import CustomSearch from "@/components/custom-base/custom-search";
export default {
  components: {
    CustomSearch,
  },
  watch: {
    $route: {
      handler(newVal, olVal) {
        this.isquick = false;
        this.iscart = false;
        this.isSearch = true;
        if (newVal.path == "/home") {
          this.active = "shouye";
          this.serchQuery = {};
          this.$store.commit("openTabbar");
        } else if (newVal.fullPath == "/goods?activityType=1") {
          this.active = "tehui";
          this.$store.commit("closeTabbar");
        }else if (newVal.fullPath == "/goods?loans=1") {
          this.active = "yaocaidai";
          this.$store.commit("closeTabbar");
        } else if (newVal.path == "/quicklyOders") {
          this.$store.commit("closeTabbar");
        } else if (newVal.query.closestTimeFlag) {
          this.active = "xiaoqi";
          this.$store.commit("closeTabbar");
        } else if (newVal.query.brandIds) {
          this.active = "kongxiao";
          this.$store.commit("closeTabbar");
        } else if (newVal.query.erpCategoryScope == "器械") {
          this.active = "qixie";
          this.$store.commit("closeTabbar");
        } else if (newVal.query.erpCategoryScope == "保健食品") {
          this.active = "baojianping";
          this.$store.commit("closeTabbar");
        } else if (newVal.query.erpCategoryScope == "中药") {
          this.active = "zhongyao";
          this.$store.commit("closeTabbar");
        } else if (newVal.path == "/quicklyOders") {
          this.$store.commit("closeTabbar");
          this.isquick = true;
          this.isSearch = false;
        } else {
          this.$store.commit("closeTabbar");
          this.iscart = false;
        }

        if (newVal.path.indexOf('/user') > -1) {
          this.showTabar = false
        } else {
          this.showTabar = true
        }
      },
      immediate: true,
    },
    active: {
      // immediate: true,
      handler(newVal, olVal) {
        switch (newVal) {
          case "shouye":
            this.$store.commit("openTabbar");
            this.$router.push("/home");
            break;
          case "yaocaidai":
            this.$router.push({ path: "/goods", query: { loans: 1 } });
            this.serchQuery = { loans: 1 };
            break;
          case "tehui":
            this.$router.push({ path: "/goods", query: { activityType: 1 } });
            this.serchQuery = { activityType: 1 };
            break;
          case "xiaoqi":
            this.$router.push({
              path: "/goods",
              query: { closestTimeFlag: 1 },
            });
            this.serchQuery = { closestTimeFlag: 1, shopType: 1 };
            break;
          case "puyao":
            this.$router.push({ path: "/goods", query: { shopType: 1 } });
            this.serchQuery = { shopType: 1 };
            break;
          case "kongxiao":
            this.$router.push({
              path: "/goods",
              query: { brandIds: "first", shopType: 2 },
            });
            this.serchQuery = { brandIds: "first", shopType: 2 };
            break;
          case "zhongyao":
            this.$router.push({
              path: "/goods",
              query: { erpCategoryScope: "中药" },
            });
            this.serchQuery = { erpCategoryScope: "中药" };
            break;
          case "qixie":
            this.$router.push({
              path: "/goods",
              query: { erpCategoryScope: "器械" },
            });
            this.serchQuery = { erpCategoryScope: "器械" };
            break;
          case "baojianping":
            this.$router.push({
              path: "/goods",
              query: { erpCategoryScope: "保健食品" },
            });
            this.serchQuery = { erpCategoryScope: "保健食品" };
            break;
          default:
            break;
        }
      },
    },
  },
  created() {
    categorysTree().then((res) => {
      this.productTree = res.data?.[0].children || [];
    });
  },
  data() {
    return {
      headerList: [
        {
          name: "首页",
          active: "shouye",
        },
        /* {
          name: "药采贷专区",
          active: "yaocaidai",
        }, */
        {
          name: "促销专区",
          active: "tehui",
        },
        {
          name: "普药",
          active: "puyao",
        },
        {
          name: "控销专区",
          active: "kongxiao",
        },
        {
          name: "效期专区",
          active: "xiaoqi",
        },
        {
          name: "中药专区",
          active: "zhongyao",
        },
        {
          name: "器械专区",
          active: "qixie",
        },
        {
          name: "保健品专区",
          active: "baojianping",
        },
      ],
      iscart: false,
      //关键词placeholder
      //关键词列表
      productTree: [],
      isquick: false,
      isSearch: true,
      active: "shouye",
      // 搜素框值
      value: "",
      serchQuery: {},
      showTabar: true
    };
  },
  methods: {
    goToGoods(childIt) {
      this.$parent.closeTab();
      this.$router.push({ path: "/goods", query: childIt });
    },
    change(name) {
      this.active = name;
      switch (name) {
        case "shouye":
          this.$store.commit("openTabbar");
          this.$router.push("/home");
          break;
        case "tehui":
          this.$router.push({ path: "/goods", query: { activityType: 1 } });
          this.serchQuery = { activityType: 1 };
          break;
        case "yaocaidai":
          this.$router.push({ path: "/goods", query: { loans: 1 } });
          this.serchQuery = { loans: 1 };
          break;
        case "xiaoqi":
          this.$router.push({ path: "/goods", query: { closestTimeFlag: 1 } });
          this.serchQuery = { closestTimeFlag: 1 };
          break;
        case "puyao":
          this.$router.push({ path: "/goods", query: { shopType: 1 } });
          this.serchQuery = { shopType: 1 };
          break;
        case "kongxiao":
          this.$router.push({
            path: "/goods",
            query: { brandIds: "first", shopType: 2 },
          });
          this.serchQuery = { brandIds: "first", shopType: 2 };
          break;
        case "zhongyao":
          this.$router.push({
            path: "/goods",
            query: { erpCategoryScope: "中药" },
          });
          this.serchQuery = { erpCategoryScope: "中药" };
          break;
        case "qixie":
          this.$router.push({
            path: "/goods",
            query: { erpCategoryScope: "器械" },
          });
          this.serchQuery = { erpCategoryScope: "器械" };
          break;
        case "baojianping":
          this.$router.push({
            path: "/goods",
            query: { erpCategoryScope: "保健食品" },
          });
          this.serchQuery = { erpCategoryScope: "保健食品" };
          break;
        default:
          break;
      }
      this.clearIput();
    },
    //调用子组件清空输入框
    clearIput() {
      this.$refs.child.clear();
    },
  },
};
</script>

<style lang="less" scoped>
@import "../assets/base.less";
::v-deep {
  .el-badge__content.is-fixed {
    position: absolute;
    top: 0;
    right: 0px;
    transform: translateY(-120%) translateX(100%);
  }
  .el-badge__content,
  .el-progress.is-exception .el-progress-bar__inner {
    background-color: #e33233;
  }
  .el-input__inner {
    border-radius: 0px;
  }
}
.download {
  padding: 5px;
  display: flex;
  align-items: center;
  border: 1px solid #eeeeee;
  .ma {
    width: 12px;
    font-size: 12px;
    font-family: PingFang HK-Regular, PingFang HK;
    font-weight: 400;
    color: #333333;
    line-height: 1.4;
  }
}
.myCart {
  margin-left: 30px;
  margin-right: 25px;
  cursor: pointer;
  position: relative;
  font-size: 12px;
  font-family: 'regular';
  font-weight: 400;
  color: #666666;
  padding: 0 20px;
  height: 44px;
  display: flex;
  align-items: center;
  border: 1px solid #eeeeee;
  .cartbage {
    height: 17px;
    width: 15px;
    background: url("../assets/images/util/cartbage.png") no-repeat;
    position: absolute;
    right: 0;
    top: -5px;
  }
}
.nav-wrapper {
  background-color: #ffffff;
  .nav {
    height: 118px;
    align-items: center;
    ul {
      li {
        float: left;
        cursor: pointer;
        margin: 0 20px;
        font-size: 16px;
        &.active {
          color: @green;
          font-weight: 700;
        }
      }
    }
    .quickly {
      width: 50%;
      margin-right: -30px;
    }
  }
  .tabar {
    .li {
      position: relative;
      text-align: center;
      padding: 7px 29px 7px 29px;
      box-sizing: border-box;
      line-height: 36px;
      height: 50px;
      font-size: 16px;
      font-weight: 400;
      display: flex;
      align-items: center;

      &:hover {
        color: @green;
      }
    }
    /* .li:after {
      content: "";
      right: 0;
      position: absolute;
      height: 20px;
      border-left: 2px solid #333;
      top: 30%;
    } */
    .li:last-child:after {
      content: "";
      right: 0;
      position: absolute;
      height: 20px;
      border-left: 0px solid #333;
      top: 30%;
    }
    .active {
      background: @green;
      color: #fff;
    }
    .active2 {
      color: @green;
    }
  }
}

.hdm {
  position: absolute;
  z-index: 999;
  height: 473px;
  position: absolute;
  top: 49.5px;
  left: 0;
  /* background-color: rgba(0,0,0,.5); */
  background-color: #fff;
  /* opacity: 0.9; */
  box-sizing: border-box;
  ul {
    width: 200px;
  }
}
.hdm .lii {
  overflow: hidden;
  height: 52px;
  border-top: 1px solid #f5f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  text-align: left;
  padding: 0 16px 0 20px;
  font-size: 14px;
  color: #4b5457;
  .icon {
    margin-right: 4px;
  }
  .navigation-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    margin-right: auto;
  }
}
.hdm > ul > li {
  background: #ffffff;
  opacity: 0.94;
}
.hdm > ul > li:hover {
  opacity: 1;
  background: #f9f9f9;
}
.hdm i {
  width: 15px;
  margin: 5px;
  color: #333;
  font-size: 12px;
}
.hdm_hidden {
  overflow: hidden;
  overflow-y: scroll;
  width: 993px;
  height: 475px;
  opacity: 1;
  background: #f9f9f9;
  /* border: 1px solid #e0e0e0;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.18); */
  position: absolute;
  top: 0;
  left: 100%;
  display: none;
  .ac {
    display: flex;
    font-size: 14px;
    color: #666666;
    padding: 0 15px;
    border-left: 1px solid #f5f5f5;
    align-items: center;
    position: relative;
    line-height: 36px;
    box-sizing: border-box;
    &::after {
      position: absolute;
      content: "";
      width: 2px;
      height: 18px;
      left: -4px;
      background-color: #e5e5e5;
    }
  }
}
.hdm li:hover .hdm_hidden {
  display: block;
}
.hdm_hidden ul {
  width: 100%;
  height: 470px;
  float: left;
}
.hdm_hidden ul > li {
  width: 100%;
  padding: 18px 20px;
  box-sizing: border-box;
  overflow: hidden;
  .left {
    display: flex;
    width: 12%;
    align-items: center;
    justify-content: flex-start;
    .span {
      margin-left: 20px;
      white-space: nowrap;
      color: #000;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .right {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    line-height: 36px;
  }
}
.hdm_hidden img {
  margin-right: 12px;
}
.hdm_hidden span {
  color: #333;
}
.hdm_hidden .ac:hover {
  color: @green;
  /* color: #ff6700; */
}
</style>
