<!--
 * @Description: description
 * @Author: booshaw
 * @Github: github
 * @Date: 2022-06-07 14:23:20
 * @LastEditors: booshaw
 * @LastEditTime: 2022-06-09 17:49:29
-->
<template>
  <div class="login bg-white">
    <div class="login-head">
      <img src="@/assets/images/login/logo.png" alt class="login-head-logo" />
    </div>
    <!-- 登录组件-->
    <div v-if="pageType === 0" class="login-wrapper">
      <div class="login-wrapper-content">
        <div class="login-wrapper-content-tabs">
          <div
            class="action-type"
            @click="onChangeSignType(SIGN_TYPE.account)"
            :class="signWithAccount ? 'active' : ''"
          >
            账号登录
          </div>
          <el-divider direction="vertical"></el-divider>
          <div
            class="action-type"
            @click="onChangeSignType(SIGN_TYPE.smsCode)"
            :class="signWithSmsCode ? 'active' : ''"
          >
            验证码登录
          </div>
        </div>
        <component :is="currentSignType"></component>
        <!-- <div class="new-box" @click="goRegister">
          <span>新用户注册</span>
          <span class="new-box-icon">></span>
        </div> -->
        <div class="protocol" @click="read">
          点击登录代表您同意
          <span class="protocol-link">《慧金采平台服务协议》</span>
        </div>
        <div style="margin: 10px -37px 0;" class="service">
          <img class="icon14 mr5" src="@/assets/images/login/erji.png" alt />
          <span class="mr5">如有疑问,联系客服:</span>
          <span class="gr">028-61622080</span>
        </div>
      </div>
    </div>
    <!-- 注册组件-->
    <Register v-if="pageType === 1" @goLogin="goLogin"></Register>
    <footer class="login-footer">
      <ul class="login-footer-link">
        <li class="login-footer-link-item">新手指南</li>
        <li class="login-footer-link-item">物流配送</li>
        <li class="login-footer-link-item">售后服务</li>
        <li class="login-footer-link-item">关于云集</li>
      </ul>
      <ul class="login-footer-address">
        <li>
          互联网药品信息服务资格证：
          <span
            class="cup"
            @click="go('../../../（川）-非经营性-2021-0221.pdf')"
            >（川）-非经营性-2021-0221</span
          >
        </li>
        <li>
          <el-divider class="divider" direction="vertical"></el-divider>
        </li>
        <li>
          <span>第二类医疗器械经营备案凭证：</span>
          <span
            class="cup"
            @click="go('../../../川蓉食药监械经营备20151485号.pdf')"
            >川蓉食药监械经营备20151485号</span
          >
        </li>
      </ul>
      <div class="login-footer-address cup">
        <span @click="go('https://beian.miit.gov.cn/')"
          >2022-2023 成都市云集药业有限公司</span
        >
        <span>蜀ICP备18003335号-2</span>
      </div>
    </footer>
  </div>
</template>

<script type="text/ecmascript-6">
import { SIGN_TYPE } from "@/utils/constant";
import CustomInput from "@/components/custom-base/custom-input/index.vue";
import Account from "./components/account.vue";
import Sms from "./components/sms.vue";
import Register from './components/register.vue'
export default {
  components: {
    CustomInput,
    Account,
    Sms,
    Register,
  },
  data() {
    return {
      signForm: {
        username: "",
        password: "",
        phone: "",
        smsCode: "",
      },
      keepPassword: false,
      currentSignType: SIGN_TYPE.account,
      SIGN_TYPE,
      codeStatus: null,
      disabled: false,
      send: "发送验证码",
      pageType: 0,   // 当前页面组件功能 0 登录, 1 注册
    };
  },

  created() {
    localStorage.removeItem("myPoint");
    localStorage.removeItem("isloan");
    localStorage.removeItem("Paycom");
  },
  mounted() {
    let type = this.$route.query.type;
    if (type) {
      this.pageType = Number(type) 
    }
  },
  computed: {
    signWithAccount() {
      return this.currentSignType === SIGN_TYPE.account;
    },
    signWithSmsCode() {
      return this.currentSignType === SIGN_TYPE.smsCode;
    },
  },
  methods: {
    read() {
      window.open(`/agreement.html`);
    },
    onChangeSignType(type) {
      this.currentSignType = type;
    },
    go(url) {
      window.open(url);
    },
    // 注册页面
    goRegister() {
      this.pageType = 1
    },
    goLogin(value){
      this.pageType = value
    }
  },
};
</script>

<style scoped lang="less">
.login {
  &-head {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 65%;
    margin: 40px auto;
    &-logo {
      width: 210px;
      height: 70px;
    }
  }
  &-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 700px;
    background: url("../../assets/images/login/banner.png") no-repeat;
    background-size: cover;
    background-position: center;
    &-content {
      margin-right: 18%;
      width: 420px;
      height: 500px;
      padding: 38px 38px 0;
      background-color: #ffffff;
      border-radius: 4px;
      color: #747c8a;
      font-size: 18px;
      &-tabs {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 28px;
        .action-type {
          font-size: 18px;
          color: #747c8a;
          cursor: pointer;
          &:hover {
            color: #505661;
          }
        }
        .active {
          color: #121f3e;
          font-weight: bold;
        }
      }
      .protocol {
        height: 38px;
        background: #F2FCF5;
        font-size: 14px;
        color: #adafad;
        text-align: center;
        line-height: 38px;
        margin: 0 -37px;
        &-link {
          color: #26c487;
          cursor: pointer;
          &:hover {
            color: #009b60;
          }
        }
      }
      .service {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 56px;
       
        line-height: 56px;
        background-color: #fbfbfb;
        font-size: 14px;
      }
    }
  }
  &-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 60px auto 30px;
    color: #666666;
    &-link {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 60%;
      padding-bottom: 20px;
      border-bottom: 1px solid #f5f5f5;
      &-item {
        padding: 0 40px;
        border-right: 1px solid #f5f5f5;
      }
    }
    &-address {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px auto 0;
      color: #666666;
      .divider {
        margin: 0 30px;
      }
    }
  }
}

.new-box {
  text-align: right;
  color: #666666;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 10px;
  .new-box-icon {
    display: inline-block;
    width: 18px;
    height: 18px;
    background: #E6E6E6;
    border-radius: 50%;
    text-align: center;
    line-height: 18px;
    color: #2B2F33;
    margin-left: 8px;
  }
}
@media screen and (max-width: 1400px) {
  .login {
    &-head {
      margin: 30px auto;
      &-logo {
        width: 150px;
        height: 50px;
      }
    }
    &-wrapper {
      height: 516px;
    }
  }
}
</style>
