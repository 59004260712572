<!--
 * @Description: description
 * @Author: booshaw
 * @Github: github
 * @Date: 2022-06-08 11:53:34
 * @LastEditors: booshaw
 * @LastEditTime: 2022-06-10 08:58:44
-->
<template>
  <div>
    <div class="sms-wrapper">
      <CustomInput v-model="phone" type="text" label="手机号" placeholder="请输入手机号"></CustomInput>
      <CustomInput v-model="smsCode"  placeholder="请输入验证码" @key-up-enter="handleSign">
        <button
          slot="append"
          type="text"
          :disabled="disabledGetSmsCode"
          class="sms-code"
          :class="disabledGetSmsCode ? 'disabled' : ''"
          @click.stop="fetchCode"
        >{{send}}</button>
      </CustomInput>
    </div>
    <el-button class="sign-button" :loading="loading" @click="handleSign">登录</el-button>
  </div>
</template>

<script type="text/ecmascript-6">
import CustomInput from '@/components/custom-base/custom-input/index.vue'
import { YzApi, loginPhoneApi } from "@/request/Login/loginApi";

export default {
  components: {
    CustomInput
  },
  data() {
    return {
      phone: '',
      smsCode: '',
      disabled: false,
      send: '获取验证码',
      loading: false
    }
  },
  computed: {
    disabledGetSmsCode() {
      return this.disabled || !this.validatePhone()
    }
  },

  methods: {
    async handleSign() {
      this.validatePhone()
      this.loading = true
      try {
        const { code, data } = await loginPhoneApi({
          smsCode: this.smsCode,
          phone: this.phone
        })
        if (code === 200) {
          sessionStorage .setItem("token", data.token);
          this._clearForm()
          this.$router.push("/home");
        }
      } catch (error) {
        this.$message.error(error)
      } finally {
        this.loading = false
      }
    },
    _clearForm() {
      this.phone = ''
      this.smsCode = ''
    },
    getCode() {
      let self = this
      if (!self.phone) {
        self.$message.warning('请输入手机号')
        return
      }
      if (self.disabled) return
      self.disabled = true
      let time = 60;
      timer && clearInterval(timer);
      const timer = setInterval(fun, 1000);
      function fun() {
        time--;
        if (time >= 0) {
          self.send = time + "s后刷新";
        } else if (time < 0) {
          self.send = "获取验证码";
          self.disabled = false;
          clearInterval(timer);
          time = 60;
        }
      }
    },
    async fetchCode() {
      this.validatePhone();
      const { code } = await YzApi({ telephone: this.phone })
      if (code === 200) {
        this.$message.success('短信发送成功')
        this.getCode()
      }
    },
    validatePhone() {
      const phoneReg = /^((\+|00)86)?1[3-9]\d{9}$/
      return phoneReg.test(this.phone)
    },
  }
}
</script>

<style scoped lang="less">
@import '../../../assets/base.less';
::v-deep{
  .input-wrapper-content .focus[data-v-b2890fba] {
    height: 46px;
    line-height: 48px;
}
}
.sms-wrapper {
  position: relative;
  width: 100%;
  .sms-code {
    color: #26c487;
    font-size: 16px;
    cursor: pointer;
    border: none;
    outline: none;
    background: transparent;
  }
  .disabled {
    color: #adafad;
    cursor: not-allowed;
  }
}
.sign-button {
  margin: 26px auto;
  width: 100%;
  height: 50px;
  background: #26c487;
  color: #ffffff;
  font-size: 16px;
  text-align: center;
}
</style>
