import request from '../request'


// export const  getInitData=(params)=> {
//     return request({
//         url: `/tssinquestion/list`,
//         method: 'GET',
//         params: params
//     })
// }
// export const  LoginApi=(data) =>{
//     return request({
//         url: `/mall/auth/login`,
//         method: 'POST',
//         data: data
//     })
// }
// export const  editTssinquestion=(data)=> {
//     return http({
//         url: `/tssinquestion`,
//         method: 'PUT',
//         data: data
//     })
// }
// export const deleteTssinquestion=(ids)=> {
//     return http({
//         url: `/tssinquestion/delete`,
//         method: 'DELETE',
//         data: ids
//     })
// }

export const  collectload=(data) =>{
    return request({
        url: `/member-v2/mall/creditarchives/collectload/record`,
        method: 'POST',
        data: data
    })
}

//获取药采贷订单列表
export const  getOrderDetailByPaySn=(data)=> {
    return request({
        url: `/order-v2/mall/order/getOrderDetailByPaySn`,
        method: 'POST',
        data: data
    })
}

//获取药采贷列表
export const  collectloadList=(params)=> {
    return request({
        url: `/member-v2/mall/creditarchives/collectloadzhiyuanyj`,
        method: 'GET',
        params: params
    })
}

//获取药采贷授信进度
export const  getInquiryStatus=(params)=> {
    return request({
        url: `/member-v2/mall/creditarchives/getInquiryStatus`,
        method: 'GET',
        params: params
    })
}
//获取药采贷统计信息(宜商行)
export const  particulars=(params)=> {
    return request({
        url: `/member-v2/mall/shoppingMall/particulars`,
        method: 'GET',
        params: params
    })
}
//确认还款请求
export const  interest=(params)=> {
    return request({
        url: `/member-v2/mall/creditarchives/interest/trial`,
        method: 'GET',
        params: params
    })
}
//确定到银行还款
export const goRepay = (params) => request.post(`/member-v2/mall/creditarchives/repay/${params.loanId}`);
//获取还款信息
export const getPrepayInfo = (params) => request.get(`/member-v2/mall/creditarchives/getPrepayInfo//${params.loanId}`);
// //药采贷详情
export const getLoanInfoByLoanId = (params) => request.get(`/member-v2/mall/creditarchives/getLoanInfoByLoanId/${params.loanId}`);

// 药采贷申请
export const  loanApplyAPI=(data)=> {
    return request({
        url: `/member-v2/outside/member/index/saveLoans`,
        method: 'POST',
        data: data
    })
}

// 天府银行用信记录
export const  creditListAPI = (data) => {
    return request({
        url: `/pay-v2/mall/pay/account/creditList`,
        method: 'POST',
        data: data
    })
}
// 获取天府银行还款地址
export const  getCreditUrlAPI = (data) => {
    return request({
        url: `/pay-v2/mall/pay/account/creditUrl`,
        method: 'POST',
        data: data
    })
}

// 获取授信统计信息
export const  creditStatisticsAPI = (type) => {
    return request({
        url: `/pay-v2/mall/pay/account/creditStatistics?bankType=${type}`,
        method: 'GET',
    })
}
