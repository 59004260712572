import request from '../request'
import qs from 'qs'


// export const  getInitData=(params)=> {
//     return request({
//         url: `/tssinquestion/list`,
//         method: 'GET',
//         params: params
//     })
// }
// export const  LoginApi=(data) =>{
//     return request({
//         url: `/mall/auth/login`,
//         method: 'POST',
//         data: data
//     })
// }
// export const  editTssinquestion=(data)=> {
//     return http({
//         url: `/tssinquestion`,
//         method: 'PUT',
//         data: data
//     })
// }
// export const deleteTssinquestion=(ids)=> {
//     return http({
//         url: `/tssinquestion/delete`,
//         method: 'DELETE',
//         data: ids
//     })
// }



// 手机号验证码登录(含注册)
///aide/user/login 账号密码登录
export const  LoginApi=(data) =>{
    return request({
        url: `user-v2/mall/auth/login`,
        method: 'POST',
        data: data
    })
}
export const  loginPhoneApi=(data) =>{
    return request({
        url: `user-v2/mall/auth/loginPhone`,
        method: 'POST',
        data: data
    })
}

export const  YzApi=(params)=> {
    return request({
        url: `user-v2/mall/auth/getAuthCode`,
        method: 'GET',
        params: params
    })
}
// export const HomeApi = () =>request.get("/index/index")
// export const LonginApi = (params) =>request.post("/auth/loginByWeb",params)
// export const HotApi = () =>request.get("/search/index")
// export const QueryApi = (params) =>request.get("/goods/list",{params})
// export const HelperApi = (params) =>request.get("/search/helper",{params})
export const RemengApi = () =>request.get("/products/recommend")
export const HotApi = () =>request.get("/products/hot")
// 验证码

// 获取用户信息
export const GetUserApi = () =>request.get("/shop/userProfiles")
// 微信扫码登录
export const WeixinLoginApi = (params) => request.post(`/wechatUsers/PCLogin`, qs.stringify(params));
// 手机绑定微信
export const BangWeixinApi = (params) => request.post(`/wechatUsers/binding`, qs.stringify(params));
// 搜索商品
export const GoodsDataApi = (params) => request.get(`/products`, {params});
// 商品详情
export const GoodsDetailsApi = (params) => request.get(`/products/${params.id}`);
// 微信扫码登录
export const WechatApi = (params) => request.post(`/shop/carts/add`,qs.stringify(params));
// 查看购物车
export const AcrtApi = () => request.get(`/shop/carts`);
// 删除购物车
export const RemoveApi = (params) => request.delete(`/shop/carts?productIds=${params.id}`);

// 获取拉新活动信息
export const getRegisterRule = ()=> {
    return request({
        url: `member-v2/outside/member/customer_apply_rule/getRegisterRule`,
        method: 'GET'
    })
}
// 拉新活动申请
export const registerApply = (data) =>{
    return request({
        url: `member-v2/outside/member/index/save`,
        method: 'POST',
        data: data
    })
}
// 获取拉新活动信息
export const getIdcard = (url)=> {
    return request({
        url: `member-v2/outside/member/ocr/getIdcard?url=${url}`,
        method: 'GET'
    })
}

// 获取拉新活动弹窗提示
export const getTipAPI = ()=> {
    return request({
        url: `member-v2/mall/customer_apply/getTipInfo`,
        method: 'GET'
    })
}