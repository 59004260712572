/*
 * @Description: description
 * @Author: booshaw
 * @Github: github
 * @Date: 2022-06-07 15:51:49
 * @LastEditors: booshaw
 * @LastEditTime: 2022-06-10 14:02:07
 */
export const SIGN_TYPE = {
  account: 'account',
  smsCode: 'sms',
}
export const CATEGORY_WORDS = [
  { name: '全部', value: null },
  { name: 'A', value: 'A' },
  { name: 'B', value: 'B' },
  { name: 'C', value: 'C' },
  { name: 'D', value: 'D' },
  { name: 'E', value: 'E' },
  { name: 'F', value: 'F' },
  { name: 'G', value: 'G' },
  { name: 'H', value: 'H' },
  { name: 'I', value: 'I' },
  { name: 'J', value: 'J' },
  { name: 'K', value: 'K' },
  { name: 'L', value: 'L' },
  { name: 'M', value: 'M' },
  { name: 'N', value: 'N' },
  { name: 'O', value: 'O' },
  { name: 'P', value: 'P' },
  { name: 'Q', value: 'Q' },
  { name: 'R', value: 'R' },
  { name: 'S', value: 'S' },
  { name: 'T', value: 'T' },
  { name: 'U', value: 'U' },
  { name: 'V', value: 'V' },
  { name: 'W', value: 'W' },
  { name: 'X', value: 'X' },
  { name: 'Y', value: 'Y' },
  { name: 'Z', value: 'Z' },
]
export const CATEGORY_KEY = {
  UNIT: 'unit',
  DOSAGE_FORM: 'dosageForm',
  CATEGORY: 'category',
}
