<!--
 * @Description: description
 * @Author: booshaw
 * @Github: github
 * @Date: 2022-06-11 10:42:33
 * @LastEditors: booshaw
 * @LastEditTime: 2022-06-11 15:14:26
-->
<template>
  <div class="search-wrapper">
    <el-input
      class="input"
      prefix-icon="el-icon-search"
      type="text"
      :placeholder="keywordPlaceholder.describe"
      v-model.trim="keyword"
      @input="onInput"
      clearable
      @keyup.native.enter="handleSearch"
    />
    <div class="btn cursor" @click="handleSearch">搜索</div>
  </div>
</template>

<script type="text/ecmascript-6">
import { Searchkeywords } from "@/request/api";
import { mapGetters } from "vuex";
export default {
  props: ["serchQuery"],
  data() {
    return {
      keyword: this.$store.state.searchKeyword || "",
      keywordPlaceholder: {
        describe: "",
      },
      searchKeywordsList: [],
      timer: null,
      token: sessionStorage.getItem('token')
    };
  },
  components: {},
  created() {
    if (this.token) {
      this.fetchKeywords();
    }
  },
  methods: {
    //清空输入框
    clear() {
      this.keyword = "";
    },
    async fetchKeywords() {
      const {
        data: { rows = [] },
        code,
      } = await Searchkeywords({
        isAsc: "asc",
        pageNum: 1,
        pageSize: 20,
      });
      if (code === 200) {
        if (rows.length > 1) {
          this.keywordPlaceholder = rows[0];
        }
        this.searchKeywordsList = rows;
        this.filterCurrentKeyword();
      }
    },
    filterCurrentKeyword() {
      if (this.searchKeywordsList.length > 1) {
        const self = this;
        self.timer = setInterval(() => {
          self.keywordPlaceholder =
            self.searchKeywordsList[
              Math.floor(Math.random() * self.searchKeywordsList.length)
            ];
        }, 5000);
      }
    },
    handleSearch() {
      if (!this.keyword) {
        this.keyword = this.keywordPlaceholder?.describe || "";
      }
      (this.serchQuery.keyword = this.keyword),

      this.$store.commit("setSearchKeyWord", this.keyword);
      this.$router.push({
        path: "/goods",
        query: this.serchQuery
      });
    },
    onInput() {
      this.$store.commit("setSearchKeyWord", this.keyword);
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>

<style scoped lang="less">
@import "../../../assets/base.less";
::v-deep {
  .el-input__inner {
    border: none !important;
  }
}
.search-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  width: 580px;
  border: 1px solid @green;
  .input {
    width: 500px;
  }
  .btn {
    width: 78px;
    background-color: @green;
    color: #ffffff;
    line-height: 44px;
    text-align: center;
    user-select: none;
  }
}
</style>
