import request from '../request'
import qs from 'qs'


// export const  getInitData=(params)=> {
//     return request({
//         url: `/tssinquestion/list`,
//         method: 'GET',
//         params: params
//     })
// }
// export const  LoginApi=(data) =>{
//     return request({
//         url: `/mall/auth/login`,
//         method: 'POST',
//         data: data
//     })
// }
// export const  editTssinquestion=(data)=> {
//     return http({
//         url: `/tssinquestion`,
//         method: 'PUT',
//         data: data
//     })
// }
// export const deleteTssinquestion=(ids)=> {
//     return http({
//         url: `/tssinquestion/delete`,
//         method: 'DELETE',
//         data: ids
//     })
// }


//获取当前登录用户信息
export const info = (params) => {
    return request({
        url: `/user-v2/mall/auth/info`,
        method: 'GET',
        params: params
    })
}
//获取当前登录用户信息
export const mallCusStaff = (params) => {
    return request({
        url: `/member-v2/mall/shoppingMall/mallCusStaff`,
        method: 'GET',
        params: params
    })
}
//获取当前登录用户信息
export const save = (params) => {
    return request({
        url: `/member-v2/mall/shoppingMall/save`,
        method: 'GET',
        params: params
    })
}
//获取当前登录用户的收货地址
export const shoppingMall = (params) => {
    return request({
        url: `/member-v2/mall/shoppingMall/shoppingMall`,
        method: 'GET',
        params: params
    })
}
//对出登陆
export const logout = (data) => {
    return request({
        url: `/user-v2/mall/auth/logout`,
        method: 'POST',
        data: data
    })
}
//充值密码
export const resetPasswordApi = (data) => {
    return request({
        url: `/user-v2/mall/user/profile/updatePayPassword`,
        method: 'POST',
        data: data
    })
}
//重置密码验证码校验
export const checkSmsCodeApi = (data) => {
    return request({
        url: `/user-v2/mall/user/profile/checkSmsCode`,
        method: 'POST',
        data: data
    })
}