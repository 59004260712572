<template>
  <footer class="footer-wrapper">
    <div class="footer-content content-wrapper">
      <div class="flex-between contact-box">
        <div class="flex-start">
          <el-popover placement="top" trigger="hover">
          <div class="center">
              <img
                src="@/assets/images/header/mobileDownloadUrl.png"
                style="width: 115px; height: 115px"
              />
            </div>

            <div class="saoma mt5">扫码下载 <span class="gr">慧金采</span>APP</div>
            <div slot="reference" class="flex-center cursor">
              <img
                src="@/assets/images/icon-mobile.png"
                width="12px"
                height="18px"
                class="mr8"
                alt
              />
              <span>移动版</span>
            </div>
          </el-popover>
          <span class="divider"></span>
          <div
            class="flex-center cursor hover"
            @click="go('https://www.yunjiyy.com/')"
          >
            <img
              src="@/assets/images/icon-website.png"
              width="16px"
              height="16px"
              class="mr8"
              alt
            />
            <span>关于云集</span>
          </div>
        </div>
        <div>
          <i class="el-icon-phone-outline mr8"></i>
          <span class="mr8">全国热线：</span>
          <span class="outline">400-072-5558</span>
        </div>
      </div>
      <div class="flex-center info-box">
        <span class="cursor hover">
          互联网药品信息服务资格证：
          <span @click="go('../../（川）-非经营性-2021-0221.pdf')">
            （川）-非经营性-2021-0221</span
          >
        </span>
        <span class="divider"></span>
        <span
          @click="go('../../川蓉食药监械经营备20151485号.pdf')"
          class="cursor hover"
        >
          <span>第二类医疗器械经营备案凭证：</span>
          <span>川蓉食药监械经营备20151485号</span>
        </span>
        <span class="divider"></span>
        <span>备案凭证：川蓉食药监械经营备20151458号</span>
      </div>
      <div class="flex-center fs12">
        <span>
          2021-{{ currentYear }} 成都市云集药业有限公司
          <span class="cursor" @click="go('https://beian.miit.gov.cn/')"
            >蜀ICP备18003335号-2</span
          >
        </span>
      </div>
    </div>
  </footer>
</template>
 
<script>
export default {
  data() {
    return {};
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
  methods: {
    look(value) {
      if (value == 1) {
      }
    },
    go(value) {
      value && window.open(value);
    },
  },
};
</script>
 
<style lang = "less">
@import "../assets/base.less";
::v-deep{
    .el-popover {
    min-width: initial;
}
}
.saoma {
  text-align: center;
  font-size: 12px;
  font-family: PingFang HK-Regular, PingFang HK;
  font-weight: 400;
  color: #333333;
}
.center {
  display: flex;
  justify-content: center;
}
.footer-wrapper {
  margin-top: 60px;
  width: 100%;
  background: #565b65;
  color: #b1b7bc;
  .footer-content {
    margin: 0 auto;
    padding: 32px 0;
    .contact-box {
      padding-bottom: 32px;
      border-bottom: 1px solid #696d71;
      color: #dee5ea;
      .outline {
        font-size: 20px;
        color: #dee5ea;
      }
    }
    .info-box {
      padding: 20px 0;
      font-size: 12px;
    }
    .divider {
      display: inline-block;
      width: 1px;
      height: 10px;
      background: #747b82;
      margin: 0 12px;
    }
  }
}
</style>