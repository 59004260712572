<!--
 * @Description: description
 * @Author: booshaw
 * @Github: github
 * @Date: 2022-06-08 08:59:01
 * @LastEditors: booshaw
 * @LastEditTime: 2022-06-10 15:21:36
-->
<template>
  <div class="input-wrapper" :class="isFocus ? 'input-active' : ''">
    <div class="input-wrapper-label" v-show="labelEnable">{{label}}</div>
    <div class="input-wrapper-content">
      <slot name="prepend"></slot>
      <input
        :value="value"
        class="input"
        :class="labelEnable ? '' : 'focus'"
        :type="typeComputed"
        @input="onInput"
        @focus="onFocus"
        @blur="onBlur"
        @keyup.enter="onKeyUpEnter"
        :placeholder="placeholder"
      />
      <slot name="append" v-show="!isFocus">
        <img
          v-if="type === 'password' && !isFocus"
          :src="passwordTypeText ? imgEyes : imgClose"
          alt
          @click.self="handleTogglePasswordType"
          class="img extend-click cursor"
        />
      </slot>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import imgEyes from '@/assets/images/login/eye.png'
import imgClose from '@/assets/images/login/closeEye.png'
export default {
  name: 'custom-input',
  props: {
    value: {
      type: String | Number,
      required: true,
      default: () => {
        return ''
      }
    },
    placeholder: {
      type: String,
      default: () => {
        return ''
      }
    },
    type: {
      type: String,
      default: () => {
        return 'text'
      }
    },
    label: {
      type: String,
      default: () => {
        return ''
      }
    }
  },
  data() {
    return {
      isFocus: false,
      passwordTypeText: false,
      imgClose,
      imgEyes,
      passwordTypeValue: 'password'
    }
  },
  computed: {
    labelEnable() {
      return this.label && this.isFocus
    },
    typeComputed({ type }) {
      return type === 'text' ? 'text' : this.passwordTypeValue
    }
  },
  methods: {
    onFocus() {
      this.isFocus = true
    },
    onBlur() {
      this.isFocus = false
    },
    onInput(e) {
      this.$emit('input', e.target.value.replace(/\s+/g, ''))
    },
    handleTogglePasswordType() {
      this.passwordTypeText = !this.passwordTypeText
      this.passwordTypeValue = this.passwordTypeText ? 'text' : 'password'
    },
    onKeyUpEnter() {
      this.isFocus = false
      this.$emit('key-up-enter')
    }
  }
}
</script>

<style scoped lang="less">
.input-wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 24px;
  width: 100%;
  height: 50px;
  border: 1px solid #e6ecf0;
  &-label {
    font-size: 12px;
    color: #adafad;
    padding: 6px 20px;
  }
  .input {
    flex-grow: 1;
    outline: none;
    padding: 0 10px;
    font-size: 16px;
    color: #333333;
    &::placeholder {
      color: #adafad;
      font-size: 16px;
    }
  }

  &-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    .img {
      display: inline-block;
      width: 16px;
      height: 8px;
    }
    .focus {
      line-height: 48px;
    }
  }
  .disabled {
    color: #adafad;
    cursor: no-drop;
  }
}
.input-active {
  border: 1px solid #26c487;
}
</style>
