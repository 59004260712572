<template>
  <div @mouseover="isup" @mouseleave="isleave" class="goods-card-wrapper">
    <div ref="List" class="img-box" @click.stop="goToDetail(value)">
      <img
        class="img"
        :src="value.firstImg !== '' ? value.firstImg : emptyGoodsImg"
        alt
      />
      <div v-if="value.loans" class="icon-box">贷</div>
    </div>

    <div class="tips">
      <div @click.stop="goToDetail(value)">
        <div class="flex-between">
          <div v-if="token" class="price">
            <CustomPrice
              :value="
                value.closestTimeFlag == 1 && value.shopType !== 2
                  ? value.validPeriodPrice
                  : value.shopType === 2
                  ? '待定价'
                  : value.activityType == 1
                  ? value.activityPrice
                  : value.salePrice
              "
              fontSize="16px"
              :fontWeight="600"
              class="mr8"
            ></CustomPrice>
            <CustomPrice
              v-if="value.shopType !== 2"
              :value="value.erpUnderlinedPrice.toFixed(2)"
              fontSize="12px"
              color="#999999"
              textDecoration="line-through"
              :fontWeight="300"
            ></CustomPrice>
          </div>
          <!-- <span v-if="!isSimple" class="stock">库存{{ value.shopType === 1 && value.erpStockNum==0 ?'代采': value.erpStockNum}}</span> -->
        </div>
        <div class="name-box">
          <div ref="name" class="name ellipsis-one">{{ value.name }}</div>
          <!-- <div class="valid-period" v-if="value.shopType === 2||!value.validPeriodTime&&value.validPeriod && !isSimple">效期{{value.validPeriod}}月</div>
          <div class="valid-period" v-if="value.shopType !== 2&&value.validPeriodTime && !isSimple">效期至{{value.validPeriodTime | dateFormat}}</div> -->
        </div>
        <div v-if="!isSimple" class="sku ellipsis-one">
          {{ value.manufacturer }}
        </div>
        <div v-if="!isSimple" class="sku ellipsis-one">
          上市许可人：{{ value.marketingAuthHolder }}
        </div>
        <div
          :style="isSimple ? 'margin-bottom:0px' : ''"
          class="sku ellipsis-one"
        >
          规格：{{ value.spec }}
        </div>
        <div v-if="!isSimple" class="sku ellipsis-one">
          {{ value.validPeriodTime ? "效期至：": "效期："}}
          <span style="color:#545454">
            {{ value.validPeriodTime  ? value.validPeriodTime : value.validPeriod + "个月" }}
          </span>
        </div>
        <div v-if="!isSimple" style="display:flex" class="sku ellipsis-one">
          <div v-if="!isSimple" style="margin-right:50px;margin-bottom:8px">
            {{
              value.shopType === 1 && value.erpStockNum == 0
                ? "代采"
                : " 库存："
            }}<span style="color:#545454">{{
              value.shopType === 1 && value.erpStockNum == 0
                ? ""
                : value.erpStockNum
            }}</span>
          </div>
          件装量：<span style="color:#545454">{{ value.packSpec }}</span>
        </div>
        <div v-if="!token" class="login-btn">售价登录可见</div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomPrice from "@/components/custom-base/custom-price";
import emptyGoodsImg from "@/assets/images/empty-product.jpg";
export default {
  props: {
    value: {
      type: Object,
      default: () => {
        return {};
      },
    },
    pageNum: {
      default: null,
    },
    keyword: {
      type: String,
      default: null,
    },
    isSimple: {
      type: Boolean,
      default: false,
    },
    closestTimeFlag: {
      type: Number || String,
      default: null,
    },
  },
  components: {
    CustomPrice,
  },
  data() {
    return {
      emptyGoodsImg,
      token:''
    };
  },
  mounted(){
    this.token = sessionStorage.getItem('token')
  },
  methods: {
    isup() {
      if (this.isSimple) {
        this.$refs.List.style = "transform: translateY(-10px);";
        this.$refs.name.style = "text-decoration:underline;color:#26c487";
      }
    },
    isleave() {
      if (this.isSimple) {
        this.$refs.List.style = "";
        this.$refs.name.style = "";
      }
    },
    //跳转到商品详情
    goToDetail(item) {
      if (this.pageNum) {
        localStorage.setItem("pageNum", this.pageNum);
      }
      if (this.keyword) {
        localStorage.setItem("keyword", this.keyword);
      }

      const { id } = this.value;
      this.$router.push({
        path: "/details",
        query: {
          FromType: this.closestTimeFlag == 1 ? 1 : 0,
          id,
        },
      });
      // let routeData = this.$router.resolve({
      //   path: "/details",
      //   query: {
      //     FromType: this.closestTimeFlag == 1 ? 1 : 0,
      //     id,
      //   },
      // });

      // window.open(routeData.resolved.fullPath, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../assets/base.less";

.goods-card-wrapper {
  // padding: 10px;
  padding-top: 10px;
  transition: all 0.1s linear;
  &:hover {
    cursor: pointer;
    // box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1);
    // .img-box {
    //   transform: scale(1.08);
    // }
  }
  .img-box {
    display: flex;
    justify-content: center;
    position: relative;
    .img {
      width: 232px;
      height: 200px;
    }
    .icon-box{
      position: absolute;
      top:-10px;
      right:0;
      width:30px;
      height:40px;
      font-size: 16px;
      color:#ffffff;
      text-align: center;
      line-height: 40px;
      background-image: url(../../../assets/images/goods-icon.svg);
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  .tips {
    padding: 0 0 0 15px;
    white-space: nowrap;
    padding-top: 12px;
    color: #999999;
    line-height: 1.6;
    font-size: 14px;
    .name-box {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      // padding-bottom: 7px;
      margin-bottom: 8px;
      .name {
        font-size: 16px;
        color: #333333;
        font-weight: 500;
        overflow: hidden;
        font-family: 'medium';
      }
      .valid-period {
        padding: 0 5px;
        // flex: 0 0 68px;
        margin-left: 8px;
        // width: 68px;
        height: 18px;
        color: #ffa936;
        font-size: 12px;
        text-align: center;
        background: #fef0df;
        border-radius: 2px;
      }
    }
    .price {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .stock {
      font-size: 12px;
      color: #8f8f8f;
    }
    .sku {
      margin-bottom: 4px;
      font-size: 12px;
      color: #999999;
    }
    .license {
      padding-top: 8px;
      color: #333333;
    }
    .unknown-price {
      font-size: 18px;
      font-weight: 600;
      color: @red;
    }
  }
}

.login-btn {
  width: 110px;
  height: 36px;
  background: linear-gradient(90deg, #26C487 0%, #5CDD61 100%);
  box-shadow: inset 0px -3px 0px 0px rgba(5,136,49,0.33);
  border-radius: 6px;
  color: #FFFFFF;
  text-align: center;
  line-height: 36px;
  margin-top: 10px;
}
</style>
