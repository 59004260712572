<!--
 * @Description: description
 * @Author: booshaw
 * @Github: github
 * @Date: 2022-06-07 11:14:32
 * @LastEditors: booshaw
 * @LastEditTime: 2022-06-14 17:23:21
-->
<template>
  <div class="service">
       <div @click="openPerson" v-if="$store.state.mallCusStaff.length>0" style="background:#26c487" class="service-item flex-column" >
         <img  src="@/assets/images/sticky/static1.png" alt="">
    </div>
    <div
      v-for="(item, index) in linkRoutes"
      :key="index"
      class="service-item"
      @click.stop="handleNavigation(item, index)"
    >
      <el-badge
        v-if="item.path === '/shoppingCart'"
        :value="$store.state.ProductNum"
      >
        <i class="icon" :class="item.icon"></i>
      </el-badge>
      <i  v-if="item.path && item.path !== '/shoppingCart'&& !item.src"  :class="item.icon" class="icon"></i>
       <img v-if="item.src" :src="item.src" alt="">
    </div>
    <div class="service-item flex-column" @click="scrollToTop">
      <i class="el-icon-arrow-up icon"></i>
      <span class="top">TOP</span>
    </div>
    <Person v-if="$store.state.mallCusStaff.length>0" @claosePerson="claosePerson" class="pos" v-show="showPerson" />
  </div>
</template>

<script type="text/ecmascript-6">
import Person from "@/components/custom-base/custom-person";
export default {
  components: {
    Person,
  },
  data() {
    return {
      showPerson: false,
      linkRoutes: [
        {
          path: "/shoppingCart",
          icon: "el-icon-shopping-cart-full",
        },
        {
          path: "/user/myOrder",
          icon: "el-icon-user",
        },
      ],
    };
  },
  methods: {
    claosePerson(){
      this.showPerson = false;
    },
    openPerson(){
 this.showPerson = true;
    },
    handleNavigation(item, index) {
      item.callback && item.callback();
      item.path && this.$router.push(item.path);
    },
    scrollToTop() {
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped lang="less">
@import "../../../assets/base.less";
.pos{
  position: absolute;
  left:-235px;
  top: 0;
}
.service {
  position: fixed;
  right: 20px;
  top: 51%;
  // transform: translateY(-50%);
  width: 52px;
  z-index: 9999;
  background: rgba(255, 255, 255, 1);
  box-sizing: border-box;
  border: 1px solid #e5e5e5;
  &-item {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #f5f5f5;
    height: 52px;
    width: 100%;
    box-sizing: border-box;
    font-size: 23px;
    color: #999999;
    &:hover {
      cursor: pointer;
      background: @green;
      color: #ffffff;
      border: none;
    }
    .top {
      font-size: 14px;
    }
  }
  .active {
    color: #ffffff;
    background-color: @green;
  }
}
</style>
