var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"goods-card-wrapper",on:{"mouseover":_vm.isup,"mouseleave":_vm.isleave}},[_c('div',{ref:"List",staticClass:"img-box",on:{"click":function($event){$event.stopPropagation();return _vm.goToDetail(_vm.value)}}},[_c('img',{staticClass:"img",attrs:{"src":_vm.value.firstImg !== '' ? _vm.value.firstImg : _vm.emptyGoodsImg,"alt":""}}),(_vm.value.loans)?_c('div',{staticClass:"icon-box"},[_vm._v("贷")]):_vm._e()]),_c('div',{staticClass:"tips"},[_c('div',{on:{"click":function($event){$event.stopPropagation();return _vm.goToDetail(_vm.value)}}},[_c('div',{staticClass:"flex-between"},[(_vm.token)?_c('div',{staticClass:"price"},[_c('CustomPrice',{staticClass:"mr8",attrs:{"value":_vm.value.closestTimeFlag == 1 && _vm.value.shopType !== 2
                ? _vm.value.validPeriodPrice
                : _vm.value.shopType === 2
                ? '待定价'
                : _vm.value.activityType == 1
                ? _vm.value.activityPrice
                : _vm.value.salePrice,"fontSize":"16px","fontWeight":600}}),(_vm.value.shopType !== 2)?_c('CustomPrice',{attrs:{"value":_vm.value.erpUnderlinedPrice.toFixed(2),"fontSize":"12px","color":"#999999","textDecoration":"line-through","fontWeight":300}}):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"name-box"},[_c('div',{ref:"name",staticClass:"name ellipsis-one"},[_vm._v(_vm._s(_vm.value.name))])]),(!_vm.isSimple)?_c('div',{staticClass:"sku ellipsis-one"},[_vm._v(" "+_vm._s(_vm.value.manufacturer)+" ")]):_vm._e(),(!_vm.isSimple)?_c('div',{staticClass:"sku ellipsis-one"},[_vm._v(" 上市许可人："+_vm._s(_vm.value.marketingAuthHolder)+" ")]):_vm._e(),_c('div',{staticClass:"sku ellipsis-one",style:(_vm.isSimple ? 'margin-bottom:0px' : '')},[_vm._v(" 规格："+_vm._s(_vm.value.spec)+" ")]),(!_vm.isSimple)?_c('div',{staticClass:"sku ellipsis-one"},[_vm._v(" "+_vm._s(_vm.value.validPeriodTime ? "效期至：": "效期：")+" "),_c('span',{staticStyle:{"color":"#545454"}},[_vm._v(" "+_vm._s(_vm.value.validPeriodTime ? _vm.value.validPeriodTime : _vm.value.validPeriod + "个月")+" ")])]):_vm._e(),(!_vm.isSimple)?_c('div',{staticClass:"sku ellipsis-one",staticStyle:{"display":"flex"}},[(!_vm.isSimple)?_c('div',{staticStyle:{"margin-right":"50px","margin-bottom":"8px"}},[_vm._v(" "+_vm._s(_vm.value.shopType === 1 && _vm.value.erpStockNum == 0 ? "代采" : " 库存：")),_c('span',{staticStyle:{"color":"#545454"}},[_vm._v(_vm._s(_vm.value.shopType === 1 && _vm.value.erpStockNum == 0 ? "" : _vm.value.erpStockNum))])]):_vm._e(),_vm._v(" 件装量："),_c('span',{staticStyle:{"color":"#545454"}},[_vm._v(_vm._s(_vm.value.packSpec))])]):_vm._e(),(!_vm.token)?_c('div',{staticClass:"login-btn"},[_vm._v("售价登录可见")]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }