<template>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="485px" :close-on-click-modal="false">
        <div class="box">
            <div class="title">
                <span>申请药采贷授信产品</span>
                <i class="el-icon-close" @click="closeDialog"></i>
            </div>
            <el-form ref="form" :model="form" :rules="formRules">
                <el-form-item label="姓名" prop="applyName">
                    <el-input v-model="form.applyName" placeholder="请输入您的姓名" clearable maxlength="16"/>
                </el-form-item>
                <el-form-item label="手机" prop="applyPhone">
                    <el-input v-model="form.applyPhone" placeholder="请输入您的联系方式" clearable maxlength="11"/>
                </el-form-item>
                <el-form-item label="选择门店作为备案信息" prop="customerId">
                    <el-select v-model="form.customerId" placeholder="请选择" @change="selectId">
                        <el-option
                        v-for="item in customerList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="营业执照号" prop="idcardNo">
                    <el-input v-model="form.idcardNo" placeholder="请输入您的执照号" disabled/>
                </el-form-item>
                <el-form-item label="开通类型" prop="type">
                    <el-radio-group v-model="form.type">
                        <el-radio :label="1">对公开通</el-radio>
                        <el-radio :label="2">对私开通</el-radio>
                        <el-radio :label="3">暂不确定</el-radio>
                    </el-radio-group>
                </el-form-item>
                <div class="button" :loading="loading" @click="submitForm('form')">提交申请</div>
            </el-form>
        </div>
    </el-dialog>
</template>

<script>
import {loanApplyAPI} from '@/request/Loan/Loan';
export default{
    props:['dialogVisible'],
    data(){
        return{
            visible:false,
            customerList: [],
            form:{},
            formRules:{
                applyName: [
                    { required: true, message: '请输入您的姓名', trigger: 'blur' },
                    {pattern:/[^`~!@#$%^&*_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*——\-+={}|《》？：“”【】、；‘'，。、]/, message: "姓名不能有特殊字符", trigger: "change",},
                ],
                applyPhone: [
                    { required: true, message: '请输入您的联系方式', trigger: 'blur' },
                    {
                        pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                        message: "请输入正确的手机号码",
                        trigger: "blur",
                    },
                ],
                customerId: [
                    { required: true, message: '请选择门店', trigger: 'blur' },
                ],
                type: [
                    { required: true, message: '请选择', trigger: 'blur' },
                ],
            },
            loading:false
        }
    },
    updated(){
        this.customerList = this.$store.state.userAddr
    },
    methods:{
        closeDialog() {
            this.$emit('close',false)
        },

        selectId(){
            this.customerList.map(i => {
                if(i.id === this.form.customerId) {
                    this.form.idcardNo = i.idcardNo
                }
            })
        },

        // 注册
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.loading = true
                    loanApplyAPI(this.form).then(res => {
                        if(res.code === 200) {
                            this.$message.success('申请成功!');
                            setTimeout(() => {
                                this.closeDialog()
                            },1000)
                        }
                        this.loading = false
                    })
                } else {
                    return false;
                }
            });
        },
    }
}
</script>

<style lang="less" scoped>
:deep .el-dialog__body{
    padding: 0;
}
:deep .el-dialog{
    border-radius: 10px;
}
:deep .el-dialog__header{
    display: none;
}
.el-select {
    width: 100%;
}
.box {
    width: 100%;
    height: 762px;
    background-image: url(../assets/images/Loan/apply-bg.png);
    background-repeat: no-repeat;
    background-size: 100%;
    .title {
        font-size: 18px;
        font-weight: 500;
        color: #333333;
        padding-top: 29px;
        font-family: 'regular';
        text-align: center;
        i {
            float: right;
            margin-right: 26px;
            cursor: pointer;
        }
    }

    .el-form {
        width: 407px;
        height: 531px;
        background: #FFFFFF;
        border-radius: 10px;
        margin: 149px auto 0;
        padding: 20px 31px;
    }
    :deep .el-input__inner{
        height: 36px;
    }
    :deep .el-form-item__label {
        height: 26px;
        font-size: 14px;
        font-family: 'regular';
    }
    .button {
        width: 345px;
        height: 50px;
        background: #26C487;
        font-size: 16px;
        font-family: 'regular';
        color: #FFFFFF;
        text-align: center;
        line-height: 50px;
        cursor: pointer;
    }
}
</style>