<template>
 <div class="toast tanxin">
     <i :class="['iconfont',$store.state.toast.icon]"></i>
     <section>{{$store.state.toast.content}}</section>
 </div>
</template>
 
<script>
export default {
 data () {
 return {

 }
 }
}
</script>
 
<style lang = "less" scoped>
@import "../assets/base.less";
 .toast{
     z-index: 999;
     padding: 10px 20px;
     background: #fff;
     position: fixed;
     top: 0;
     left: 50%;
     transform: translateX(-50%);
     border-radius: 8px;
         box-shadow: 0 0 10px #000;
     section{
         margin-left: 10px;
     }
 }
 .icon-toast_chenggong{
     color: lawngreen;
 }
 .icon-toast-shibai_huaban{
     color: red;
 }
 .icon-toast-jinggao{
     color: orange;
 }
 .icon-loading{
     color: blue;
 }
</style>