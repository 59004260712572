import request from '../request'
import qs from 'qs'


// export const  getInitData=(params)=> {
//     return request({
//         url: `/tssinquestion/list`,
//         method: 'GET',
//         params: params
//     })
// }
// export const  LoginApi=(data) =>{
//     return request({
//         url: `/mall/auth/login`,
//         method: 'POST',
//         data: data
//     })
// }
// export const  editTssinquestion=(data)=> {
//     return http({
//         url: `/tssinquestion`,
//         method: 'PUT',
//         data: data
//     })
// }
// export const deleteTssinquestion=(ids)=> {
//     return http({
//         url: `/tssinquestion/delete`,
//         method: 'DELETE',
//         data: ids
//     })
// }

// 上传文件api
export function uploadAPI(data) {
    return request({
        url: '/oss/admin/sys/oss/upload',
        method: 'post',
        data
    })
}
// 对码管理开始对码
export function otrherCodeAPI(data) {
    return request({
        url: '/other-code/admin/other/product/otrherCode',
        method: 'post',
        data
    })
}


// 加入购物车
export const addCartApi = (data) => {
    return request({
        url: `/order-v2/mall/cart/saveCart`,
        method: 'POST',
        data: data
    })
}
// 一键加入购物车
export const oneKeyShop = (data) => {
    return request({
        url: `/other-code/mall/goodsBase/oneKeyShop`,
        method: 'POST',
        data: data
    })
}
// 快速下单批量加入购物车
export const quickOrder = (data) => {
    return request({
        url: `/order-v2/mall/cart/quickOrder`,
        method: 'POST',
        data: data
    })
}
//商城端-返回分类管理条件集合
export const searchconditions = (params) => {
    return request({
        url: `/product-v2/mall/product/categorys`,
        method: 'GET',
        params: params
    })
}
//商城端-返回banner列表
export const bannerList = (params) => {
    return request({
        url: `/user-v2/outside/mall/list`,
        method: 'GET',
        params: params
    })
}
//商城端-返回品牌
export const brandmanages = (params) => {
    return request({
        url: `/product-v2/outside/product/index/brandmanages`,
        method: 'GET',
        params: params
    })
}
//商城端-返回剂型,单位分类
export const searchconditionsMedical = (params) => {
    return request({
        url: `/product-v2/mall/searchconditions`,
        method: 'GET',
        params: params
    })
}
//商品多条件查询
export const searchProducts = (params) => {
    return request({
        url: `/search-v2/mall/search/product/keywords`,
        method: 'GET',
        params: params
    })
}
//精心推荐商品列表(未登录)
export const recommendProductList = (data) => {
    return request({
        url: `/product-v2/outside/product/index/recommendProductList`,
        method: 'POST',
        data: data
    })
}
//精心推荐商品列表(已登录)
export const recommendProductListLogin = (data) => {
    return request({
        url: `/product-v2/mall/recommendProductList`,
        method: 'POST',
        data: data
    })
}
//商品多条件查询
export const brandmanageList = (params) => {
    return request({
        url: `/search-v2/mall/brandmanage/list`,
        method: 'GET',
        params: params
    })
}
//商品详情
export const productsDetail = (params) => {
    return request({
        url: `/product-v2/mall/products/${params.id}`,
        method: 'GET',
        params: params
    })
}
// export const productsDetail = (params) => request.get(`/product-v2/mall/products/${params.id}`);