<template>
  <div>
    <div class="left">
      <div class="img-box">
        <img class="leftImg" :src="previewImg" alt />
        <div class="tips">
          <span class="label">温馨提示：</span>
          图片仅供参考；商品实际包装可能因生产批次不同而变化，实际产品包装以到货为准。
        </div>
      </div>
      <!-- 鼠标层罩 -->
      <div v-show="topShow" class="top" :style="topStyle"></div>
      <!-- 最顶层覆盖了整个原图空间的透明层罩 -->
      <div class="maskTop" @mouseenter="enterHandler" @mousemove="moveHandler" @mouseout="outHandler"></div>
    </div>
    <div v-show="rShow" class="right">
      <img :style="r_img" class="rightImg" :src="zoomImg" alt />
    </div>
  </div>
</template>


 
<script>
export default {
  data() {
    return {
      topStyle: { transform: "" },
      r_img: {},
      topShow: false,
      rShow: false,
    };
  },
  props: {
    previewImg: {
      type: String,
      default: ''
    },
    zoomImg: {
      type: String,
      default: ''
    },
    tips: {
      type: String,
      default: ''
    }
  },
  methods: {
    // 鼠标进入原图空间函数
    enterHandler() {
      // 层罩及放大空间的显示
      this.topShow = true;
      this.rShow = true;
    },
    // 鼠标移动函数
    moveHandler(event) {
      // 鼠标的坐标位置
      let x = event.offsetX;
      let y = event.offsetY;
      // 层罩的左上角坐标位置，并对其进行限制：无法超出原图区域左上角
      let topX = x - 100 < 0 ? 0 : x - 100;
      let topY = y - 100 < 0 ? 0 : y - 100;
      // 对层罩位置再一次限制，保证层罩只能在原图区域空间内
      if (topX > 238) {
        topX = 238;
      }
      if (topY > 238) {
        topY = 238;
      }
      // 通过 transform 进行移动控制
      this.topStyle.transform = `translate(${topX}px,${topY}px)`;
      this.r_img.transform = `translate(-${2 * topX}px,-${2 * topY}px)`;
    },
    // 鼠标移出函数
    outHandler() {
      // 控制层罩与放大空间的隐藏
      this.topShow = false;
      this.rShow = false;
    },
  },
};
</script>


 
<style lang="less" scoped>
@import '../../assets/base.less';
/* 放大的图片，通过定位将左上角定位到(0,0) */
.rightImg {
  display: inline-block;
  width: 800px;
  height: 800px;
  position: absolute;
  top: 0;
  left: 0;
} /* 右边的区域图片放大空间 */
.right {
  //   margin-left: 412px;
  position: absolute;
  top: 0;
  left: 466px;
  width: 438px;
  height: 438px;
  // border: 1px solid @hui;
  //   position: relative;
  overflow: hidden;
} /* 一个最高层层罩 */
.maskTop {
  width: 438px;
  height: 438px;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
} /* 层罩，通过定位将左上角定位到(0,0) */
.top {
  width: 200px;
  height: 200px;
  background-color: #333;
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
} /* 原图的显示 */
.img-box {
  position: relative;
  width: 438px;
  height: 438px;
  .leftImg {
    width: 438px;
    height: 438px;
    display: inline-block;
  }
  .tips {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 50px;
    padding: 12px;
    background: rgba(0, 0, 0, 0.2);
    color: #F5F5F5;
    line-height: 1.4;
    box-sizing: border-box;
    font-size: 12px;
  }
}
/* 原图的容器 */
.left {
  overflow: hidden;
  width: 438px;
  height: 438px;
  border: 1px solid #e8e8e8;
  float: left;
  position: relative;
}
</style>
