
/*
 * @Description: description
 * @Author: booshaw
 * @Github: github
 * @Date: 2022-06-01 15:21:55
 * @LastEditors: booshaw
 * @LastEditTime: 2022-06-11 20:20:46
 */
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import 'reset-css';
import ElementUI from 'element-ui'
import '../theme/index.css'
import "@/assets/text/text.css";
import "@/assets/base.less";
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import {
  download
} from '@/request/request'

Vue.prototype.download = download
Vue.use(Viewer)
Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.filter('dateFormat', function (originVal) {

  const dt = new Date(originVal)

  const y = dt.getFullYear()

  const m = (dt.getMonth() + 1 + '').padStart(2, '0')

  const d = (dt.getDate() + '').padStart(2, '0')

  const hh = (dt.getHours() + '').padStart(2, '0')

  const mm = (dt.getMinutes() + '').padStart(2, '0')

  const ss = (dt.getSeconds() + '').padStart(2, '0')
  // 时间格式年月日、时分秒 
  // return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
  return `${y}-${m}-${d} `
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
