<template>
  <div class="home">
    <!-- 拉新活动弹窗-->
    <el-dialog :visible.sync="dialogVisible" width="550px">
      <div class="dialog-box">
        <div class="dialog-top">恭喜您【{{dialogInfo.tipCustomerName || '--'}}】获得新用户福利 <span>'{{dialogInfo.tipAmt || '--'}}慧金豆'</span>,可在个人中心-我的慧金豆中查看明细</div>
        <div class="dialog-center">
          <img src="../assets/images/mallCusStaff/warring.png" alt="">
          <span>支付时，系统会自动使用慧金豆默认抵扣订单金额</span>
        </div>
        <div class="button" @click="dialogVisible = false">关闭</div>
      </div>
    </el-dialog>

    <router-view></router-view>
    <!-- 注册图-->
    <!-- <div v-if="showRegister" class="register-box">
      <img class="close" src="../assets/images/home/close.png" alt="" @click="showRegister = false" />
      <img class="main" src="../assets/images/home/register.png" alt="" @click="routeRegister" />
    </div> -->
    <!-- 轮播图 -->
    <div class="banner">
      <el-carousel
        arrow="never"
        class="carousel-wrapper"
        height="470px"
        :interval="5000"
      >
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <img
            @click="goPage(item, index)"
            class="banner-img cup"
            :src="item.bannerPic"
            alt
          />
          <!-- <img
            class="banner-img cup"
            src="@/assets/images/home/9527.png"
            alt
          /> -->
        </el-carousel-item>
      </el-carousel>
    </div>
    <div :class="$route.path != '/login' ? 'content-wrapper' : ''">
      <!-- 控销专区 -->
      <div class="control">
        <MyTitle :brandIds="true" title="控销专区" />
        <div class="df">
          <div class="left-webAttachUrl">
            <img src="@/assets/images/home/contorBanner.png" alt="" />
          </div>
          <div class="right-webAttachUrl">
            <li v-for="(item, index) in contorlwebAttachUrl" :key="index">
              <div @click="goGoods(item)" class="img">
                <img
                  style="width:100%;max-height:auto"
                  :src="item.webAttachUrl"
                  alt=""
                />
              </div>
            </li>
          </div>
        </div>
      </div>

      <MyTitle title="精心推荐" />
      <ul class="recommend-list">
        <li v-for="(item, index) in allList" :key="index" class="item">
          <GoodsCard :value="item" :isSimple="true"></GoodsCard>
        </li>
      </ul>
    </div>
    <!-- 暂时屏蔽客户服务 -->
    <CustomService></CustomService>
  </div>
</template>

<script>
import MyTitle from "@/components/Home/MyTitle.vue";
import Myswiper from "@/components/Myswiper/Myswiper.vue";
import {
  searchProducts,
  recommendProductList,
  recommendProductListLogin,
  brandmanages,
  bannerList,
} from "@/request/goods/good";
import {getTipAPI} from '@/request/Login/loginApi'
import CustomPrice from "@/components/custom-base/custom-price";
import CustomService from "@/components/custom-base/custom-service";
import GoodsCard from "@/views/Goods/components/goods-card.vue";
export default {
  components: {
    CustomPrice,
    MyTitle,
    Myswiper,
    GoodsCard,
    CustomService,
  },
  data() {
    return {
      contorlwebAttachUrl: [],
      bannerList: [
        {
          img: require("@/assets/images/home/Banner2.jpg"),
          url: "/goods",
          query: { activityType: 1 },
        },
        {
          img: require("@/assets/images/home/Banner.png"),
          url: "/goods",
          query: { activityType: 1 },
        },
      ],
      GoodsList: [],
      allList: [],
      page: 1,
      //触底节流
      timerDebounce: false,
      // 注册图示
      token:sessionStorage.getItem('token'),
      showRegister:true,
      dialogVisible:false,
      dialogInfo:{},
    };
  },

  created() {
    setTimeout(() => {
      this.timerDebounce = true;
    }, 500);
    bannerList({ category: 1, pageNum: 1, pageSize: 999, state: 0 }).then(
      (res) => {
        if (res.code === 200) {
          sessionStorage.removeItem('topBack')
          let arr = []
          res.data.rows.map(i => {
            if(i.type === 1) {
              arr.push(i)
            }
            if(i.type === 2) {
              sessionStorage.setItem('topBack',i.bannerPic);
            }
          })
          this.bannerList = arr;
        }
      }
    );
    brandmanages({
      pageNum: 1,
      pageSize: 10,
    }).then((res) => {
      if (res.code === 200) {
        this.contorlwebAttachUrl = res.data.rows;
        this.contorlwebAttachUrl.sort(this.compare("defineSort"));
      }
    });
    this.getList();
    // this.getTipFun();
  },
  mounted() {
    window.addEventListener("scroll", this.scrollBottom);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollBottom);
  },

  methods: {
    // 登录后获取提示
    getTipFun(){
      if(this.token) {
        getTipAPI().then(res => {
          if(res.code === 200){
            this.dialogInfo = res.data;
            this.dialogInfo.tipFlag === 1 ? this.dialogVisible = true : this.dialogVisible = false
          }
        })
      }else {
        this.showRegister = true
      }
    },
    // 跳转注册
    routeRegister(){
      this.$router.push({path:"/login", query:{type: 1}});
    },
    //排序
    compare(property) {
      return function(a, b) {
        var value1 = a[property];
        var value2 = b[property];
        return value1 - value2;
      };
    },
    //跳转品牌专区
    goGoods(item) {
      this.$router.push({
        path: "/goods",
        query: { brandIds: item.id, shopType: 2 },
      });
    },
    goPage(item, index) {
      let url = {};
      if (item.urlLink === "/goods" || item.urlLink === "/details") {
        if (item.parameter) {
          url = JSON.parse(item.parameter);
        }
        this.$router.push({
          path: item.urlLink ? item.urlLink : "/goods",
          query: item.params ? url : {},
        });
      } else {
        if (item.urlLink) {
          window.open(item.urlLink);
        }
      }
    },

    //监听滚动
    scrollBottom() {
      if (this.timerDebounce) {
        let scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let clientHeight = document.documentElement.clientHeight;
        let scrollHeight = document.documentElement.scrollHeight;
        if (scrollTop + clientHeight >= scrollHeight) {
          this.page++;
          this.timerDebounce = false;
          this.getList();
          setTimeout(() => {
            this.timerDebounce = true;
          }, 300);
        }
      }
    },
    //获取商品列表
    getList() {
      let Data = {
        pageSize: 10,
        pageNum: this.page,
      }; 
      let requestURL = this.token ? recommendProductListLogin : recommendProductList;
      requestURL(Data).then((res) => {
        if (res.code === 200) {
          this.GoodsList = res.data.rows;
          this.GoodsList.map((item) => {
            item.transImg = item.attachUrl.split(",");
            if (item.transImg.length !== 0) {
              let urlImg = [];
              item.transImg.map((it) => {
                if (it.lastIndexOf("show") !== -1) {
                  it.replace(
                    "show",
                    "showcompress?compressType=size&value=264x264"
                  );
                }
              });
              item.firstImg = item.attachUrl?.split(",")?.[0] || item.attachUrl;
            }
          });

          this.allList = [...this.allList, ...this.GoodsList];
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import url("../assets/base.less");

::v-deep {
  .el-badge__content.is-fixed {
    position: absolute;
    top: 14px;
    right: 10px;
    transform: translateY(-50%) translateX(100%);
  }
  .el-badge__content,
  .el-progress.is-exception .el-progress-bar__inner {
    background-color: #e33233;
  }
}
.control {
  .left-webAttachUrl {
    width: 200px;
    height: 260px;
  }
  .right-webAttachUrl {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    li {
      min-width: 20%;
      max-width: 20%;
      height: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-top: 1px solid #f5f5f5;
      border-right: 1px solid #f5f5f5;
      border-bottom: 1px solid #f5f5f5;
      .img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 138px;
        height: 64px;
      }
    }
  }
}
.home {
  position: relative;
  .banner {
    width: 100%;
    height: 470px;
    //background: #0077d5;
    .carousel-wrapper {
      width: 100%;
      overflow-y: hidden;
      //background: #0077d5;
      overflow: hidden;
      .banner-img {
        width: 100%;
        height: 470px;
        object-fit: cover;
      }
    }
  }
}
.list {
  margin-bottom: 58px;
  ul {
    display: flex;

    li {
      margin-right: 14px;
    }
  }
  ul :last-child {
    margin-right: 0;
  }
  .img {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 20px;
    width: 290px;
    height: 350px;
    background-color: #cae5f8;
    opacity: 1;
    border-radius: 6px;
    .waikuang {
      box-sizing: border-box;
      width: 250px;
      height: 140px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      border: 10px solid #ffffff;
      .neirong {
        box-sizing: border-box;
        padding: 10px;
        width: 210px;
        height: 106px;
        background: #ffffff;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        .dashed {
          padding-bottom: 8px;
          box-sizing: border-box;
          border-bottom: 1px dashed @hui;
        }
      }
    }
  }
}

.list2 {
  display: flex;
  margin-bottom: 58px;
  .time {
    padding: 5px;
    width: 50%;
    .goodsbox {
      height: 300px;
      display: flex;
      .goods_left {
        .c {
          margin-top: 20px;
          font-size: 20px;
          color: #707070;
          margin-bottom: 41px;
        }
        .price {
          span {
            color: #e33233;
          }
        }
        .buy {
          margin-top: 20px;
        }
      }
      .goods_right {
        flex: 1;
        ::v-deep .el-carousel__container {
          position: relative;
          height: 262px !important;
        }
      }
    }
  }
  .quick {
    padding: 5px;
    width: 50%;
    .goodsbox {
      height: 300px;
      display: flex;
      .goods_left {
        width: 215px;
        height: 260px;
        background-color: #3333;
        border-radius: 4px;
      }
      .goods_right {
        flex: 1;
        ul {
          display: flex;
          justify-content: space-around;
          flex-wrap: wrap;

          li {
            height: 44px;
            width: 49%;
            margin-top: 12px;
            margin-bottom: 40px;
            background-color: @blue;
          }
          .span:nth-child(2n)::after {
            display: inline-block;
            vertical-align: middle;
            border-left: 1px solid red;
            content: "";
            height: 40px;
            line-height: 40px;
            margin-top: 3px;
          }
        }

        li :last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.recommend-list {
  margin: 0 -10px;
  .item {
    display: inline-block;
    width: 20%;
    opacity: 1;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 40px;
  }
}
.ad_img {
  display: block;
  margin-bottom: 30px;
}
.jifen {
  background: #fff;
  ul {
    padding-bottom: 47px;
    li {
      width: 285px;
      height: 168px;
      transition: all 0.3s linear;
      h3 {
        font-size: 30px;
        font-weight: bold;
        color: #ffffff;
        margin: 43px 0 0 20px;
      }
      .btn {
        cursor: pointer;
        width: 96px;
        height: 27px;
        border: 1px solid #ffffff;
        text-align: center;
        line-height: 27px;
        color: #fff;
        margin: 19px 0 0 20px;
      }
      &:hover {
        background-size: 105% 105%;
      }
    }
  }
}

.register-box {
  position: fixed;
  width: 300px;
  height: 300px;
  top: 60px;
  right: 10px;
  z-index: 9;
  .close {
    float: right;
    margin-right: 10px;
  }
  .main {
    top: -30px;
  }
  img {
    cursor: pointer;
  }
}

 ::v-deep .el-dialog{
  background: transparent;
  box-shadow: none;
  .el-dialog__header{
    display: none;
  }
  .el-dialog__body{
    padding: 0;
  }
}
.dialog-box {
  width: 100%;
  height: 466px;
  background-image: url(../assets/images/home/dialog-bg.png);
  background-size: contain;
  overflow: hidden;
  .dialog-top {
    width: 454px;
    font-size: 15px;
    font-weight: 400;
    color: #333333;
    margin: 212px auto 0;
    text-align: center;
    line-height: 30px;
    span {
      color: #FF1A2E;
      font-size: 20px;
    }
  }
  .dialog-center{
    width: 376px;
    height: 60px;
    background: #FFF9ED;
    border-radius: 6px;
    margin: 30px auto;
    font-size: 14px;
    color: #FF7D00;
    text-align: center;
    line-height: 20px;
    padding: 10px 11px 0;
    img {
      margin-right: 10px;
      vertical-align: middle;
    }
  }
  .button {
    width: 114px;
    height: 38px;
    border-radius: 10px;
    background: #5FE5B1;
    font-size: 14px;
    color: #FFFFFF;
    text-align: center;
    line-height: 38px;
    margin: 0 auto;
    cursor: pointer;
  }
}
</style>
