<template>
  <div class="banxin">
    <Breadcrumb />
    <div class="cotent banxin tanxin">
      <!-- 提示弹窗 -->
      <div class="toast">
        <el-dialog :visible.sync="dialogVisible" width="550px">
          <div class="flex-between">
            <div class="flex-center">
              <img
                style="margin-right: 15px"
                src="@/assets/images/details/addCart.png"
                alt=""
              />
              <div class="dialog-txt">
                <div class="stepNum">{{ stepNum }}</div>
                件{{ detailList.name }} 已成功加入您的购物车
              </div>
            </div>
            <i
              @click="dialogVisible = false"
              style="font-size: 18px"
              class="cup el-dialog__close el-icon el-icon-close"
            ></i>
          </div>
        </el-dialog>
      </div>
      <div class="main_l">
        <pic-zoom :mainImgUrl="firstAtc" :imgUrlList="urlImg"></pic-zoom>
        <div v-if="detailList.loans" class="icon-box">贷</div>
      </div>
      <div class="main_r">
        <h2 style="padding-left:20px">{{ detailList.name }}</h2>
        <div class="gezi mt20">
          <div class="left">
            <span class="label">售价</span>
            <custom-price
              :value="
                detailList.skuList && detailList.skuList.length > 0
                  ? detailList.salePrice
                  : detailList.shopType == 2
                  ? '待定价'
                  : detailList.activityType == 1
                  ? detailList.activityPrice
                  : detailList.salePrice
              "
              :fontWeight="600"
            ></custom-price>
            <custom-price
              v-if="detailList.shopType == 1"
              style="margin-left:10px"
              :value="detailList.erpUnderlinedPrice"
              textDecoration="line-through"
              fontSize="14px"
              color="#707070"
              :fontWeight="200"
            ></custom-price>
            <div v-if="detailList.shopType != 2" class="df aic mt20">
              <div style="margin-right: 46px; color: #adafad">起订量</div>
              <div style="color:#707070">≥{{ detailList.minSales }}</div>
            </div>
          </div>
          <div style="flex-direction: column" class="right aic">
            <div class="mt5" style="color: #adafad">累计销售</div>
            <div class="leiji mt20">{{ detailList.sellTotal }}</div>
          </div>
        </div>
        <li class="mt20">
          <div class="name">生产厂家:</div>
          <div class="code">{{ detailList.manufacturer }}</div>
        </li>
        <li>
          <div class="name">上市许可人:</div>
          <div class="code">{{ detailList.marketingAuthHolder }}</div>
        </li>
        <div class="descriptions">
          <ul>
            <li>
              <div class="name">商品编号:</div>
              <div class="code">{{ detailList.erpCode }}</div>
            </li>
            <li>
              <div class="name">规格:</div>
              <div class="code">{{ detailList.spec }}</div>
            </li>
            <!-- <li>
              <div class="name">产地:</div>
              <div class="code">{{ detailList.place }}</div>
            </li> -->
            <li>
              <div class="name">批准文号:</div>
              <div class="code">{{ detailList.approvalNo }}</div>
            </li>
            <li>
              <div class="name">单位:</div>
              <div class="code">{{ detailList.unit }}</div>
            </li>
            <li v-if="xqList.length == 0">
              <div class="name">
                {{ detailList.validPeriodTime ? "效期至" : "效期" }}：
              </div>
              <div class="code">
                {{ detailList.validPeriodTime ? detailList.validPeriodTime : detailList.validPeriod + "个月" }}
              </div>
            </li>

            <li>
              <div class="name">件装量:</div>
              <div class="code">
                {{ detailList.packSpec }}
              </div>
            </li>
            <li>
              <div class="name">
                {{
                  detailList.shopType === 1 && detailList.erpStockNum == 0
                    ? "代采"
                    : "库存:"
                }}
              </div>
              <div class="code">
                {{
                  detailList.shopType === 1 && detailList.erpStockNum == 0
                    ? ""
                    : detailList.erpStockNum
                }}
              </div>
            </li>
            <li>
              <div class="name">中包装:</div>
              <div class="code">{{ detailList.minSales }}</div>
            </li>
            <!--           
            <li>
              <div class="name">剂型:</div>
              <div class="code">{{ detailList.medicalType }}</div>
            </li> -->
          </ul>
          <div v-if="xqList.length > 0" class="df mb20">
            <div class="xqname">效期:</div>
            <div style="flex-wrap: wrap;" class="df">
              <div
                @click="cahngexiaoq(item, index)"
                v-for="(item, index) in xqList"
                :key="index"
                :class="xqactive == index ? 'xqactive' : ''"
                class="xqlist  cup"
              >
                {{ item.xqName }}
              </div>
            </div>
          </div>
        </div>

        <div class="df">
          <div class="bjq">
            <div class="input">
              <input
                type="number"
                @input="onInput"
                oninput="if(!/^[0-9]+$/.test(value)) value=value.replace(/\D/g,'');"
                @blur="changeBuy(detailList.minSales)"
                v-model="stepNum"
              />
            </div>
            <div class="ax">
              <div class="action" @click="addFn(detailList.minSales)">+</div>
              <div
                class="action"
                :class="stepNum == detailList.minSales ? 'disabled' : ''"
                @click="cutFn(detailList.minSales)"
              >
                -
              </div>
            </div>
          </div>
          <div class="botton">
            <div class="bt" @click="addCart">
              <img
                style="diaplay: inline-block; margin-right: 10px"
                src="@/assets/images/details/detailsCart.png"
                alt
              />
              加入购物车
            </div>
          </div>
        </div>

        <div class="orangetishi fs12">
          <i
            style="margin-left: 10px; color: #ffa936"
            class="el-icon-warning"
          ></i>
          温馨提示：所选数量为中包装，只能按照中包装整数倍数购买。
        </div>
      </div>
    </div>
    <!-- 相关推荐 -->
    <!-- <div class="banxin" style="margin-top:70px;">
   
      <div class="tt df"> <div class="suxian"></div> 相关推荐</div>
      <div class="div mt30">
      <RecommendList style="margin: 20px 0" :list="6" />

      </div>
    </div>-->
    <div class="banxin last" style="margin-bottom: 90px; margin-top: 60px">
      <!-- 看了又看 -->
      <!-- <div class="repeak">
        <div class="tt">看了又看</div>
        <div class="kan">
          <ul>
            <li class="cup" v-for="(item, index) in 2" :key="index">
              <div class="img">
                <img src="@/assets/images/home/9999999.png" alt="" />
              </div>
              <div style="margin-top:33px" class="fs14 blod">阿莫西林</div>
              <div class="price mt10">¥ 119</div>
            </li>
          </ul>
        </div>
      </div>-->

      <div class="nav">
        <!-- <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane label="商品详情" name="first">
            <div v-html="detailList.details"></div>
          </el-tab-pane>
          <el-tab-pane label="说明书" name="second">
            <div v-html="detailList.specification"></div>
          </el-tab-pane>
          <el-tab-pane label="注册批件" name="third">
            <div v-html="detailList.approval"></div>
          </el-tab-pane>
          <el-tab-pane label="推荐商品" name="fourth">推荐商品</el-tab-pane>
        </el-tabs> -->
        <div class="flex-start green">
          <img src="../../assets/images/details/card.png" alt="" />
        </div>
        <div class="nav-box">
          <div class="html">
            <!-- 商品详情 -->
            <div v-html="detailList.details"></div>
            <!-- 商品说明书 -->
            <div v-html="detailList.specification"></div>
          </div>
        </div>
        <img src="../../assets/images/details/detailCard.png" alt="" />
      </div>
      <div class="person">
        <el-dialog
          class="flex-center"
          :close-on-click-modal="false"
          :visible.sync="personDialog"
          width="260px"
        >
          <Person :isgoods="true" @claosePerson="claosePerson" />
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import Person from "@/components/custom-base/custom-person";
import RecommendList from "@/components/Recommend/recommend.vue";
import PicZoom from "@/components/PicZoom/pictrue.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import { productsDetail, addCartApi } from "@/request/goods/good";
import { findProductList } from "@/request/CartApi/cart";
import CustomPrice from "@/components/custom-base/custom-price";
export default {
  components: {
    Person,
    RecommendList,
    PicZoom,
    Breadcrumb,
    CustomPrice,
  },
  data() {
    return {
      personDialog: false,
      firstDown: false,
      secondDown: false,
      xqList: [],
      urlImg: [],
      firstAtc: "",
      dialogVisible: false,
      activeName: "first",
      // 图片当前项
      cIndex: 0,
      // 库存
      stepNum: 1,
      detailList: {},
      xqactive: null,
      pchao: null,
    };
  },
  methods: {
    //切换效期
    cahngexiaoq(item, index) {
      this.detailList.salePrice = item.price;
      this.detailList.erpStockNum = item.stock;
      this.pchao = item.pchao;
      this.xqactive = index;
    },
    //关闭经理弹窗
    claosePerson() {
      this.personDialog = false;
    },
    changeBuy(minSales) {
      if (this.stepNum % minSales !== 0) {
        this.stepNum = minSales * Math.floor(this.stepNum / minSales);
      }
    },
    async fetchDetail() {
      const { data, code } = await productsDetail({
        id: this.$route.query.id || "",
        cusUid: this.$store.state.owerId,
      });
      if (code === 200) {
        if (data.skuList) {
          data.skuList.map((item) => {
            if (item.stock != 0) {
              this.xqList.push(item);
            }
          });
        }
        this.detailList = data;
        this.stepNum = this.detailList.minSales;
        this.detailList.imgList = this.detailList?.attachUrl.split(",");
        this.detailList.firstAtc = this.detailList.imgList[0];
      }
    },
    handleClick(tab, event) {},
    onInput(e) {
      this.stepNum = Number(e.target.value);
      if (
        this.detailList.shopType == 2 &&
        this.stepNum > this.detailList.erpStockNum
      ) {
        this.$message.warning("购买数量不能大于库存");
        this.stepNum =
          this.detailList.minSales *
          Math.floor(this.detailList.erpStockNum / this.detailList.minSales);
      } else if (
        this.xqList.length > 0 &&
        this.stepNum > this.detailList.erpStockNum
      ) {
        this.$message.warning("购买数量不能大于库存");
        this.stepNum =
          this.detailList.minSales *
          Math.floor(this.detailList.erpStockNum / this.detailList.minSales);
      }
    },
    // 步进器增加
    addFn(minSales) {
      this.stepNum += minSales * 1;
      if (
        this.detailList.shopType == 2 &&
        this.stepNum > this.detailList.erpStockNum
      ) {
        this.$message.warning("购买数量不能大于库存");
        this.stepNum =
          minSales * Math.floor(this.detailList.erpStockNum / minSales);
      } else if (
        this.xqList.length > 0 &&
        this.stepNum > this.detailList.erpStockNum
      ) {
        this.$message.warning("购买数量不能大于库存");
        this.stepNum =
          this.detailList.minSales *
          Math.floor(this.detailList.erpStockNum / this.detailList.minSales);
      }
    },
    // 步进器减少
    cutFn(minSales) {
      if (this.stepNum <= 1) {
        return;
      }
      this.stepNum -= minSales * 1;
    },
    //加入购物车
    addCart() {
      if (!this.$store.state.AddrId) {
        this.$message.warning("请先选择收货地址");
        return;
      }
      let itemReqDTO = {
        pchao: this.pchao,
        productFromType: 0,
        customerAddrId: this.$store.state.AddrId,
        productId: this.detailList.id,
        productQuantity: this.stepNum,
      };
      addCartApi(itemReqDTO).then((res) => {
        if (res.code === 200) {
          // this.$message({
          //   message: "已加入购物车",
          //   type: "success",
          // });
          this.dialogVisible = true;
          if (this.dialogVisible == true) {
            setTimeout(() => {
              this.dialogVisible = false;
            }, 1500);
          }

          findProductList({
            customerId: this.$store.state.AddrId,
          }).then((response) => {
            if (response.code === 200) {
              let productNum = response.data.productList.length;
              this.$store.commit("setProductNum", productNum);
            }
          });
        } else {
          this.personDialog = true;
        }
      });
    },
  },
  created() {
    window.scrollTo(0, 0);
    productsDetail({
      id: this.formGoods.id,
      fromType: this.formGoods.FromType,
      cusUid: this.$store.state.AddrId,
    }).then((res) => {
      if (res.code === 200) {
        this.detailList = res.data;
        this.stepNum = res.data.minSales;
        this.detailList.imgList = this.detailList.attachUrl.split(",");
        //效期
        if (res.data.skuList) {
          this.xqList = res.data.skuList;
          if (this.xqList.length > 0) {
            this.detailList.erpStockNum = this.xqList[0].stock;
            this.detailList.salePrice = this.xqList[0].price;
            this.xqactive = 0;
            this.pchao = this.xqList[0].pchao;
          }
        }
        if (this.detailList.imgList.length !== 0) {
          this.detailList.imgList.map((it) => {
            // if (it.lastIndexOf("show") !== -1) {
            //   this.urlImg.push(
            //     it.replace(
            //       "show",
            //       "showcompress?compressType=size&value=264x264"
            //     )
            //   );
            // }
            this.urlImg.push(it);
          });
        }

        this.firstAtc = this.urlImg[0];
        7;
      }
    });
  },
  watch: {
    $route: {
      handler(n, o) {
        this.formGoods = n.query;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/base.less";
::v-deep {
  .el-dialog {
    .el-dialog__header {
      display: none;
    }
  }
  .toast {
    .el-dialog__body {
      border-left: 3px solid #26c487;
      padding: 25px;
      background: #ffffff;
    }
    .el-dialog__header {
      display: none;
    }
  }
  .el-dialog__body {
    /* padding: 15px 30px 30px 30px; */
    padding: 30px;
  }
  .el-tab-pane {
    padding: 20px;
  }
  .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border-left: 0;
  }
  .nav {
    border-left: 1px solid #e4e7ed;
  }
  .el-tabs__nav-scroll {
    height: 53px;
    background-color: #f9f9f9;
  }
  .is-active {
    background-color: #fff;
  }
  .el-tabs__item {
    text-align: center;
    font-size: 18px;
    height: 53px;
    line-height: 53px;
    padding: 0 44px !important;
    box-sizing: border-box;
    font-weight: 400;
  }
  .person {
    .el-dialog__header {
      display: none;
    }
    .el-dialog {
      background: none;
      box-shadow: none;
    }
  }
}
.green {
  background: rgba(38, 196, 135, 0.2);
}
.nav-box {
  margin-bottom: 30px;
  background: #ffffff;
  border: 2px solid rgba(38, 196, 135, 0.2);
  .html {
    padding: 20px 30px;
  }
}
.xqname {
  padding-top: 8px;
  height: 100%;
  padding-left: 20px;
  width: 12%;
  font-size: 14px;
  color: #707070;
  text-align-last: left;
}
.xqactive {
  border: 1px solid @green !important;
  background: url("../../assets/images/details/goodsActive.png") no-repeat;
  background-position: right bottom;
}
.xqlist {
  font-size: 12px;
  font-family: 'regular';
  font-weight: 400;
  color: #333333;
  margin-bottom: 15px;
  margin-right: 15px;
  padding: 9px 14px;
  border: 1px solid #e5e5e5;
}
.dialog-txt {
  display: flex;
  line-height: 1.4;
  font-size: 16px;
  font-family: 'regular';
  font-weight: 400;
  color: #333333;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.tt {
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  color: #333333;
}
.stepNum {
  font-size: 16px;
  font-family: 'regular';
  font-weight: bold;
  color: #ffa936;
}

.last {
  display: flex;
  .repeak {
    width: 224px;
    background: #ffffff;
    border: 1px solid #e7ebe7;
    .tt {
      font-size: 18px;
      margin-left: 10px;
      height: 53px;
      background: #f9f9f9;
      line-height: 53px;
    }

    .kan {
      ul {
        display: flex;
        flex-direction: column;
        li {
          box-sizing: border-box;
          padding: 15px;
          width: 224px;
          height: 200px;
          border: 1px solid @hui;
          .img {
            margin: 0 50%;
            transform: translateX(-64px);
            img {
              width: 128px;
              height: 92px;
            }
          }
          .tt {
            margin-top: 10px;
            height: 16px;
            font-size: 14px;
            font-weight: 600;
            line-height: 2;
            color: #333333;
          }
          .price {
            margin-top: 10px;
            height: 28px;
            font-size: 20px;
            font-weight: bold;
            line-height: 32px;
            color: #e33233;
          }
        }
      }
    }
  }
  .nav {
    ::v-deep {
      .el-tabs--card > .el-tabs__header .el-tabs__item {
        border-top: 3px solid transparent;
      }
    }
    flex: 1;
  }
}

.descriptions {
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 30px;
  ul {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    li {
      margin-bottom: 18px;
      width: 50%;
      display: flex;
      padding-right: 12px;
      .name {
        padding-left: 20px;
        width: 25%;
        font-size: 14px;
        color: #707070;
        text-align-last: left;
        line-height: 1.3;
      }
      .code {
        font-size: 14px;
        color: #333333;
        flex: 1;
        line-height: 1.3;
      }
    }
  }
}
li {
  margin-bottom: 18px;
  width: 50%;
  display: flex;
  padding-right: 12px;
  .name {
    padding-left: 20px;
    width: 25%;
    font-size: 14px;
    color: #707070;
    text-align-last: left;
    line-height: 1.3;
  }
  .code {
    font-size: 14px;
    color: #333333;
    flex: 1;
    line-height: 1.3;
  }
}
.max_img {
  width: 492px;
  height: 492px;
  border: 1px solid #e7ebe7;
  border: 1px solid @hui;
}
.gezi {
  display: flex;
  align-items: center;
  padding: 20px 0 20px 20px;
  background: #f8f8f8;
  .left {
    flex: 1;
    border-right: 1px solid #e8e8e8;
    .label {
      padding-right: 58px;
      color: #adafad;
    }
  }
  .right {
    margin-left: auto;
    flex: 0 0 116px;
    text-align: center;
    box-sizing: border-box;
    .leiji {
      height: 18px;
      font-size: 16px;
      font-weight: 700;
      color: #26c487;
    }
  }
}

.div {
  border: 1px solid @hui;
  margin-bottom: 20px;
}
.tt {
  margin-bottom: 0;
}
.cotent {
  min-height: 500px;
  /* background: pink; */

  .main_l {
    position: relative;
    margin-right: 40px;
    width: 438px;
    .tishi {
      bottom: 22%;
      position: absolute;
      padding: 20px;
      width: 100%;
      line-height: 1.8;
      color: #ffffff;
      background: rgba(0, 0, 0, 0.1);
    }
    .icon-box{
      position: absolute;
      top:0;
      right:0;
      width:45px;
      height:60px;
      font-size: 20px;
      color:#ffffff;
      text-align: center;
      line-height: 50px;
      background-image: url(../../assets/images/goods-icon.svg);
      background-size: cover;
      background-repeat: no-repeat;
    }

    img {
      width: 100%;
      display: block;
      margin-bottom: 20px;
    }
    ul {
      display: flex;
      justify-content: space-between;
      li {
        width: 100px;
        cursor: pointer;
        opacity: 0.5;
        &.active {
          opacity: 1;
        }
      }
    }
  }

  .main_r {
    flex: 1;
    h2 {
      font-weight: 600;
      font-size: 20px;
    }
    p {
      font-size: 14px;
      color: #999999;
      margin: 15px 0 26px;
    }
    .section {
      height: 42px;
      font-size: 20px;
      font-weight: bold;
      color: #e33233;
    }
    h3 {
      font-size: 14px;
      color: #000000;
      margin-bottom: 14px;
    }
    .orangetishi {
      height: 34px;
      line-height: 34px;
      background: #fffae5;
      color: #707070;
      border: 1px solid #ffe29f;
    }
    .bjq {
      width: 100px;
      display: flex;
      border: 1px solid #e7ebe7;
      margin-bottom: 20px;
      .ax {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #707070;
        .action {
          user-select: none;
          width: 40px;
          height: 28px;
          font-size: 19px;
          box-sizing: border-box;
          background-color: #e9e9e9;
          line-height: 28px;
          text-align: center;
          cursor: pointer;
        }
        .disabled {
          color: #cccccc;
          cursor: not-allowed;
          pointer-events: none;
        }
      }

      .input {
        display: flex;
        input {
          font-size: 19px;
          height: 60px;
          width: 60px;
          text-align: center;
          box-sizing: border-box;
          background-color: transparent;
        }
      }
    }
    .botton {
      display: flex;
      .bt {
        font-size: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 212px;
        height: 60px;
        background: #26c487;
        color: #fff;
        text-align: center;
        line-height: 60px;
        margin-left: 16px;
        cursor: pointer;
      }
      .btn {
        background: red;
      }
    }
  }
}
.bottom {
  border-top: 1px solid #ccc;
  padding-bottom: 30px;
  ul {
    justify-content: flex-start;
    li {
      padding: 15px 20px;
      font-size: 18px;
      color: #999;
      &.active {
        font-weight: bold;
        color: #333333;
        background: #ececec;
      }
    }
  }
  h3 {
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  p {
    line-height: 1.2;
  }
  a {
    color: #333;
  }
}
</style>
