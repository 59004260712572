<!--
 * @Description: description
 * @Author: booshaw
 * @Github: github
 * @Date: 2022-06-01 14:39:34
 * @LastEditors: booshaw
 * @LastEditTime: 2022-06-07 13:34:41
-->
<template>
  <div class="recommendList">
    <ul>
      <!-- @click="$router.push(`/details&id=${index}`)" -->
      <li class="cup" v-for="(item, index) in list" :key="index">
        <div class="por">
          <div class="img">
            <!-- <img src="@/assets/images/home/9999999.png" alt /> -->
          </div>
          <div class="tt">阿莫西林</div>
          <div class="price">¥ 119</div>
        </div>
        <div class="suxian"></div>
      </li>
    </ul>
  </div>
</template>
 
<script>
export default {
  props: ["list"],
  data() {
    return {};
  },
};
</script>
 
<style lang = "less" scoped>
@import '../../assets/base.less';
.recommendList {
  ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    li {
      position: relative;
      box-sizing: border-box;
      padding: 24px 44px 14px 34px;
      width: 224px;
      height: 200px;
      /* border: 1px solid @hui; */
      .suxian {
        position: absolute;
        right: 0;
        top: 28%;
        line-height: 100px;
        width: 1px;
        height: 100px;
        background: @hui;
      }

      .img {
        margin: 0 50%;
        transform: translateX(-64px);
        img {
          width: 128px;
          height: 92px;
        }
      }
      .tt {
        margin-bottom: 0;
        margin-top: 10px;
        height: 16px;
        font-size: 16px;
        font-weight: 600;
        line-height: 2;
        color: #333333;
      }
      .price {
        margin-top: 10px;
        height: 28px;
        font-size: 20px;
        font-weight: bold;
        line-height: 32px;
        color: #e33233;
      }
    }
    li:nth-child(6) {
      .suxian {
        width: 0px;
      }
    }
  }
}
</style>