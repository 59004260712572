<template>
  <div>
    <div>
      <div style="width:438px;height:438px">
        <!-- <img :src="mainImgUrl" style="width:100%;height:100%"> -->
        <pic-zoom :previewImg="mainImgUrl==''?defaultImg:mainImgUrl" :zoomImg="mainImgUrl==''?defaultImg:mainImgUrl"></pic-zoom>
      </div>
      <div class="navigation-box">
        <div class="action-box">
          <i class="el-icon-arrow-left action" @click="imgLeft()"></i>
        </div>
        <ul class="Img_ul">
          <li v-for="(item,index) in imgUrlList" :key="index" class="Img_li" :style="imgStyle" @click="changeImg(item, index)">
            <img :class="index === imgActiveIndex ? 'img_activeBorder' : ''" :src="item==''?defaultImg:item" class="img" />
          </li>
        </ul>
        <div class="action-box">
          <i class="el-icon-arrow-right action" @click="imgRight()"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PicZoom from '@/components/PicZoom/PicZoom.vue'
export default {
  components: {
    PicZoom
  },
  props: {
    mainImgUrl: {
      type: String,
      default: ""
    },
    imgUrlList: {
      default: function () {
        return []
      }
    }
  },
  mounted() {
  },
  
  data() {
    return {
      defaultImg:require('@/assets/images/details/defaultImg.png'),
      imgActiveIndex: 0, // 当前移动图片的索引值
      imgDistance: 0, // 移动的距离
      allDistance: 0, // 总移动距离

    }
  },
  computed: {
    imgStyle() {
      return {
        transform: `translate3d(${this.imgDistance}px, 0, 0)` // 计算移动的距离(x,y,z)
      }
    }
  },
  methods: {
    changeImg(item, index) {
      this.mainImgUrl = item
      this.imgActiveIndex = index
    },
    imgLeft() {
      if (this.imgActiveIndex > 0) {
        this.imgActiveIndex--  // 索引值-1
        this.imgUrlList.forEach((item, index) => { // 循环小图,通过判断索引值赋值给大图
          if (this.imgActiveIndex === index) {
            this.mainImgUrl = item
          }
        })
      }
      if (this.imgActiveIndex >= 3) {
        var index = 0
        const temp = window.setInterval(() => { // 利用定时器实现图片左右移动的动画效果
          if (index < 50) { // 移动次数(50次)
            this.imgDistance += 2 // 每次向左移动的距离 (移动总距离为50*this.imgDistance)
            index++
            return
          } else {
            window.clearInterval(temp) // 移动完清除定时器
          }
        }, 10)
      }
    },
    imgRight() {
      if (this.imgActiveIndex < this.imgUrlList.length - 1) {
        this.imgActiveIndex++
        this.imgUrlList.forEach((item, index) => {
          if (this.imgActiveIndex === index) {
            this.mainImgUrl = item
          }
        })
        if (this.imgActiveIndex >= 4) {
          this.allDistance = -120 * (this.imgActiveIndex - 4)
          var index = 0
          const temp = window.setInterval(() => {
            if (index < 50) {
              this.imgDistance -= 2 // 每次向右移动的距离
              index++
              return
            } else {
              window.clearInterval(temp)
            }
          }, 10)
        }
      } else if (this.imgActiveIndex === this.imgUrlList.length - 1 ) { // 到达最后一张图片，再点击跳转回第一张
        this.imgActiveIndex = 0;
        this.imgDistance=0;
        this.allDistance=0;
        this.mainImgUrl = this.imgUrlList[0]
        var index = 0
        const temp = window.setInterval(() => { // 利用定时器实现图片左右移动的动画效果
          if (index < Math.abs(this.allDistance / 2)) { // 取绝对值再除
            this.imgDistance += 2 // 每次向左移动的距离 (移动总距离为50*this.imgDistance)
            index++
            return
          } else {
            window.clearInterval(temp) // 移动完清除定时器
          }
        }, 1)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.Img_ul {
  position: relative;
  display: flex;
  width: 100%;
  height: 75px;
  padding: 0 8px;
  overflow: hidden;
  list-style: none;
}
.Img_li {
  float: left;
  margin: 0 8px;
  cursor: pointer;
  .img {
    height: 70px;
    width: 70px;
    border: 1px solid #f5f5f5;
    overflow: hidden;
    outline: none;
  }
}
.img_activeBorder {
  border: 2px solid #0dcc73 !important;
}
.navigation-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px auto;

  .action-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 75px;
    border: 1px solid #f5f5f5;
    color: #adadad;
    &:hover {
      color: #26c487;
      border: 1px solid #26c487;
      cursor: pointer;
    }
    .action {
      font-size: 30px;
    }
  }
}
</style>
