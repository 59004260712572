<template>
  <div class="breadcumb">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item v-for="(item, index) in lists" :key="index">
        <!-- <a :href="index+1===lists.length?'#':item.path">{{ item.meta.title }}</a> -->
        <span
          @click="index + 1 === lists.length ? '' : $router.push(item.path)"
          >{{ item.meta.title }}</span
        >
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lists: [],
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        this.lists = to.matched;
      },
    },
  },
  created() {
    this.lists = this.$route.matched; //获取路由内的全部信息
    if (this.lists[0].meta.title == "商品详情") {
      this.lists = [
        {
          path: "/home",
          meta: {
            title: "首页",
          },
        },
        {
          path: "/goods",
          meta: {
            title: "商品分类",
          },
        },
        {
          path: this.$route.fullPath,
          meta: {
            title: "商品详情",
          },
        },
      ];
    } else if (this.lists[0].path == "/user") {
      this.lists[0].path == "/user/myOrder";
    }
  },
};
</script>

<style lang="less" scoped>
.breadcumb {
  height: 48px;
  line-height: 48px;
  display: flex;
  align-items: center;
}
</style>
