<template>
  <el-carousel arrow="always" :autoplay="false" height="204px">
    <el-carousel-item
      style="justify-content: space-between"
      class="el-car-item"
      v-for="(list, index) in dataList"
      :key="index"
    >
      <div
        @click="goDetail(index2)"
        v-for="(imgList, index2) in list"
        :key="index2"
        class="gezi"
      >
        <div class="hezi">
          <img
            style="width: 173px; height: 123px"
            class="top-img"
            :src="imgList.url"
            :alt="imgList.title"
          />
          <div
            style="
              margin-top: 26px;
              margin-left: 37px;
              margin-top: 26px;
              text-align: left;
            "
            class="fs16 blod"
          >
            复方熊胆薄荷含片
          </div>
          <div
            style="
              margin-top: 12px;
              margin-left: 37px;
              margin-top: 26px;
              text-align: left;
            "
            class="fred fs14"
          >
            ￥18.00
          </div>
        </div>
        <div class="su"></div>
      </div>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
export default {
  data() {
    return {
      dataList: [],
    };
  },
  created() {
    this.arrList();
  },
  methods: {
    goDetail(index) {},
    arrList() {
      let newDataList = [];
      let current = 0;
      if (this.dataList && this.dataList.length > 0) {
        for (let i = 0; i <= this.dataList.length - 1; i++) {
          if (i % 4 !== 0 || i === 0) {
            if (!newDataList[current]) {
              newDataList.push([this.dataList[i]]);
            } else {
              newDataList[current].push(this.dataList[i]);
            }
          } else {
            current++;
            newDataList.push([this.dataList[i]]);
          }
        }
      }
      this.dataList = [...newDataList];
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep {
  .el-carousel__arrow--left {
    left: 0;
  }
  .el-carousel__arrow--right {
    right: 0;
  }
}

.gezi:nth-child(4n) {
  .su {
    display: none;
  }
}
.su {
  margin-top: 50px;
  width: 0px;
  height: 95px;
  border: 1px solid #e7ebe7;
  opacity: 1;
}
.hezi {
  width: 100%;
  text-align: center;
}
.gezi {
  box-sizing: border-box;
  padding: 37px 0 0 0;
  display: flex;
  width: 24%;
  height: 250px;
  /* background: orange; */
  /* border: 1px solid #F3F3F3; */
  opacity: 1;
  border-radius: 4px;
}
.el-car-item {
  width: 100%;
  display: flex;
  .top-img {
    width: 284px;
    height: 184px;
    margin-right: 20px;
    cursor: pointer;
  }
}
</style>
