<!--
 * @Description: description
 * @Author: booshaw
 * @Github: github
 * @Date: 2022-06-01 16:12:22
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-12-22 15:40:17
-->
<template>
  <div id="app">
    <div v-if="!maintenanceIcon">
      <Advertisement />
      <Header v-if="$route.path != '/login'"/>
      <Nav v-if="$route.path != '/login'"/>
      <div class="xian"></div>
      <div @click="closeTab" :class="['/goods', '/details', '/home'].includes($route.path) ? 'bg-white' : 'bg-gray'" >
        <router-view @keyGai="headerKey++" :class="['/login', '/home',].includes($route.path) ? '' : 'content-wrapper'" />
        <Footer :style="$route.path == '/home'?'padding-bottom:10px':''" :class="fixed?'Footer':''" v-if="$route.path != '/login'" />
        <transition name="fade">
          <Toast v-show="$store.state.toast.show" />
        </transition>
      </div>
    </div>
    <div v-else style="display: flex;height:100%;align-items: center;justify-content: center;">
      <img src="../src/assets/images/update.jpg" alt="">
    </div>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import Nav from "@/components/Nav.vue";
import Toast from "./components/Toast.vue";
import Advertisement from '@/components/advertisement.vue';
export default {
  components: {
    Footer,
    Header,
    Nav,
    Toast,
    Advertisement
  },
  data() {
    return {
      //底部定位
      fixed: false,
      headerKey: 0,
      payFixed: true,
      maintenanceIcon: false, // 维护标识
    };
  },
  provide() {
    return {
      fixed: this.payFixedFn  // 订单列表页批量支付
    }
  },
  mounted() {
    window.addEventListener("scroll", this.Scrollbottom);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.Scrollbottom);
  },
  methods: {
    //监听滚动
    Scrollbottom() {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      const clientHeight = document.documentElement.clientHeight;
      const scrollHeight = document.documentElement.scrollHeight;
      /* if (this.$route.path == '/home') {
        if (scrollTop + clientHeight >= 1900) {
          this.fixed = true
        } else {
          this.fixed = false
        }
      } */
      if (this.$route.path == '/user/myOrder') {
        if ((scrollHeight - scrollTop) > 1200) {
          this.payFixed = true
        } else {
          this.payFixed = false
        }
      }
    },
    closeTab() {
      if (this.$route.path !== "/home" && this.$store.state.showTabar != false) {
        this.$store.commit("closeTabbar");
      }
    },
    payFixedFn() {
      return this.payFixed;
    }
  },
};
</script>

<style lang="less">
@import './assets/base.less';

.el-pager {
  font-weight: 400;
}
.Footer {
  z-index: 9999999;
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.xian {
  width: 100%;
  height: 2px;
  background: #26c487;
  opacity: 1;
  border-radius: 0px;
}
.content-wrapper {
  position: relative;
  width: 1400px;
  min-width: 1180px;
  margin: 0 auto;
}
// @media screen and(max-width: 1280px) {
//   .content-wrapper {
//     width: 1180px;
//   }
// }
@media screen and(max-width: 1400px) {
  .content-wrapper {
    width: 1180px;
  }
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s linear;
}
.fade-enter-to,
.fade-leave {
  opacity: 1;
}
.cup {
  cursor: pointer;
}
a {
  text-decoration: none;
}
input {
  outline: none;
}
</style>
