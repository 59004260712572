export default {
    namespaced: true,
    state: {
        // 搜索商品数据
        goodsObj: {
            did: 0,
            type: 0,
            min: 0,
            max: 10000,
            keyword: ""
        }
    },
    mutations: {
        // 更改商品搜素数据
        changGoodsObj(state, payload) {
            // keys方法查看这个对象中的所有key,并返回一个数组
            let arr = Object.keys(payload)
            arr.map(item => {
                // 通过 hasOwnProperty() 判断state.goodsObj是否有这个key
                if (state.goodsObj.hasOwnProperty(item)) {
                    // 如果有这个值就把就数组的这个key值改为新的
                    state.goodsObj[item] = payload[item]
                }
            })
        }
    }
}