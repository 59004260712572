<!--
 * @Description: description
 * @Author: booshaw
 * @Github: github
 * @Date: 2022-06-01 15:21:55
 * @LastEditors: booshaw
 * @LastEditTime: 2022-06-02 16:41:54
-->
<template>
  <div>
    <div class="title tanxin">
      <div class="title_l">
        <!-- <img :src="src" alt /> -->
        <div v-if="this.title!='限时秒杀'" class="tt df">
          <div class="suxian"></div>
          {{ this.title }}
        </div>
        <div v-if="this.title=='限时秒杀'" class="tt df">
          <div class="suxian"></div>
          <img src="../../assets/images/home/clear.png" alt />
          <div style="margin-top:14px;height:40px;line-height: 40px;" class="df aic">
            <i style="font-size:14px;margin-left:20px" class="el-icon-time icon20 df aic" />
            <div class="fs14 fhui">剩余</div>
            <div class="fs18 fred">00</div>
            <div class="fs14 fhui">时</div>
            <div class="fs18 fred">00</div>
            <div class="fs14 fhui">分</div>
            <div class="fs18 fred">33</div>
            <div class="fs14 fhui">秒</div>
          </div>
        </div>
      </div>
      <div class="title_r df aic" v-if="title !== '积分攻略'" @click="goGoods">
        <p style="    font-size: 16px;
    font-family: 'regular';
    font-weight: 400;
    color: #707070;">更多</p>
        <img style="width:15px;height:15px" src="../../assets/images/home/Arrow.png" alt />
      </div>
    </div>
  </div>
</template>
 
<script>
export default {
  data() {
    return {};
  },
  props: ["src", "brandIds", "title"],
  methods: {
    goGoods() {
      if (this.brandIds) {
        this.$router.push({
          path: "/goods",
          query: { brandIds: "first", shopType: 2 },
        });
      } else {
        this.$router.push({ path: "/goods" });
      }
    },
  },
};
</script>
 
<style lang = "less" scoped>
@import "../../assets/base.less";
.fred {
  margin: 0 5px;
  font-size: 18px;
}
.suxian {
  width: 6px;
  height: 28px;
  margin-right: 12px;
  background: linear-gradient(30deg, #08ce96 0%, #23dba7 100%);
  opacity: 1;
  border-radius: 2px;
}
.tt {
  font-size: 22px;
  margin-bottom: 0;
}
.title {
  width: 100%;
  height: 60px;
  margin-top: 20px;
  align-items: center;
  .title_l {
    img {
      margin-right: 8px;
    }
  }
  .title_r {
    cursor: pointer;
    display: flex;
    font-size: 12px;
    img {
      margin-left: 4px;
    }
  }
}
</style>