import Vue from 'vue'
import Vuex from 'vuex'
import goods from './goods'
import pic from '@/assets/images/empty-product.jpg'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    custmerAddname: "",
    //专属客服
    mallCusStaff: [],
    //药采贷权限
    loan: false,
    //我的积分权限
    myPoint: false,
    owerId: sessionStorage.getItem('setOwerId') || null,
    Imgpic: pic,
    //购物车数量
    ProductNum: 0,
    showTabar: false,
    AddrId: sessionStorage.getItem('AddrId') || null,
    customerType: sessionStorage.getItem('tomerType') || null,
    userAddr: [],
    toast: {
      show: false,
      icon: "icon-toast_chenggong",
      content: "你好世界"
    },
    searchKeyword: ''

  },
  mutations: {
    setmallCusStaff(state, payload) {
      state.mallCusStaff = payload
    },
    setcustmerAddname(state, payload) {
      state.custmerAddname = payload
    },

    closeTabbar(state, payload) {
      state.showTabar = false
    },
    loan(state, payload) {
      state.loan = payload
    },
    myPoint(state, payload) {
      state.myPoint = payload
    },
    setOwerId(state, payload) {
      state.owerId = payload
    },

    openTabbar(state, payload) {
      state.showTabar = true
    },
    setProductNum(state, payload) {
      state.ProductNum = payload
    },
    changeAddrId(state, payload) {
      state.AddrId = payload
    },
    changeCustomerType(state, payload) {
      state.customerType = payload
    },
    //用户地址列表
    saveuserAddr(state, payload) {
      state.userAddr = payload
    },
    // 打开toast
    openToast(state, payload) {
      state.toast.show = true
      state.toast.content = payload.content
      switch (payload.icon) {
        // 警告
        case "info":
          state.toast.icon = "icon-toast-jinggao";
          break;
          // 成功
        case "success":
          state.toast.icon = "icon-toast_chenggong";
          break;
          // 失败
        case "danger":
          state.toast.icon = "icon-toast-shibai_huaban";
          break;
          // 加载中
        case "loading":
          state.toast.icon = "icon-loading";
          break;
        default:
          state.toast.icon = "icon-toast_chenggong";
          break;
      }
    },
    // 同步关闭
    closeToast(state) {
      state.toast.show = false
    },
    // 搜索
    setSearchKeyWord(state, payload) {
      state.searchKeyword = payload
    }
  },
  actions: {
    closeTab({
      commit
    }) {
      // 提交同步
      commit('closeTabbar');
    },
    changeCustmerAddname({
      commit
    }, payload) {
      // 提交同步
      commit('setcustmerAddname', payload);
    },
    dispatchchangeAddrId({
      commit
    }, payload) {
      // 提交同步
      commit('changeAddrId', payload);
    },
    dispatchchangecustomerType({
      commit
    }, payload) {
      // 提交同步
      commit('customerType', payload);
    },
    // 要求payload是{ icon , content }
    // this.$store.dispatch("tosetAsync", { icon: 'danger', content: res.message })
    // icon : info | success | danger | loading 
    tosetAsync({
      commit
    }, payload) {
      commit("openToast", payload)
      setTimeout(() => {
        commit("closeToast")
      }, 2000);
    }
  },
  modules: {
    goods
  }
})