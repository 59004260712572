import request from '../request'
import qs from 'qs'


// export const  getInitData=(params)=> {
//     return request({
//         url: `/tssinquestion/list`,
//         method: 'GET',
//         params: params
//     })
// }
// export const  LoginApi=(data) =>{
//     return request({
//         url: `/mall/auth/login`,
//         method: 'POST',
//         data: data
//     })
// }
// export const  editTssinquestion=(data)=> {
//     return http({
//         url: `/tssinquestion`,
//         method: 'PUT',
//         data: data
//     })
// }
// export const deleteTssinquestion=(ids)=> {
//     return http({
//         url: `/tssinquestion/delete`,
//         method: 'DELETE',
//         data: ids
//     })
// }

// 提交议价
export const  applyBargaining=(data) =>{
    return request({
        url: `/order-v2/mall/cart/applyBargaining`,
        method: 'POST',
        data: data
    })
}
//修改购物车
export const  updateCart=(data)=> {
    return request({
        url: `/order-v2/mall/cart/updateCart`,
        method: 'PUT',
        data: data
    })
}
//删除
// export const deleteCartItemIos = (params) => request.delete(`/order-v2/mall/cart/deleteCartItem?cartItemId=${params.cartItemId}`);
// 提交议价
export const  deleteCartItemIos=(data) =>{
    return request({
        url: `/order-v2/mall/cart/deleteCartItemV2`,
        method: 'POST',
        data: data
    })
}
// export const  deleteCartItemIos=(cartItemId)=> {
//     return request({
//         url: `/order-v2/mall/cart/deleteCartItem`,
//         method: 'DELETE',
//         data: cartItemId 
//     })
// }
// 加入购物车
export const  addCartApi=(data) =>{
    return request({
        url: `/order-v2/mall/cart/saveCart`,
        method: 'POST',
        data: data
    })
}
// 商品选择
export const  UpdateSelFlag=(data) =>{
    return request({
        url: `/order-v2/mall/cart/UpdateSelFlag`,
        method: 'POST',
        data: data
    })
}
//获取用户商品列表
export const  findProductList=(params)=> {
    return request({
        url: `/order-v2/mall/cart/findProductList`,
        method: 'GET',
        params: params
    })
}
///收货地址详情
export const findReceiveDetail = (params) => request.get(`/order-v2/mall/cart/findReceiveDetail/${params.customerId}`);
