import request from './request'



//商城端获取商品分类管理树
export const  categorysTree=(params)=> {
    return request({
        url: `/product-v2/outside/product/index/categorys/tree`,
        method: 'GET',
        params: params
    })
}
//商城搜索关键字
export const  Searchkeywords=(params)=> {
    return request({
        url: `/product-v2/mall/products/keywords`,
        method: 'GET',
        params: params
    })
}