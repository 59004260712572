import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login/login.vue'
import details from '../views/Details/Details.vue'
import Store from '../store'
import {
  Backtop
} from 'element-ui'

Vue.use(VueRouter)
Vue.use(Store)
const routes = [{
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta:{
      auth: false
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/goods',
    name: 'Goods',
    meta: {
      title: '商品列表',
      isTab: true,
      auth: true
    },
    component: () => import( /* webpackChunkName: "goods" */ '../views/Goods/Goods.vue')
  },
  {
    path: '/details',
    name: 'Details',
    meta: {
      title: '商品详情',
      auth: true
    },
    component: details
  },
  {
    path: '/shoppingCart',
    name: 'ShoppingCart',
    meta: {
      title: '购物车',
      isTab: true,
      auth: true
    },
    component: () => import( /* webpackChunkName: "shoppingCart" */ '../views/ShoppingCart/ShoppingCart.vue')
  },
  {
    path: '/quicklyOders',
    name: 'QuicklyOders',
    meta: {
      title: '快速下单',
      isTab: true,
      auth: true
    },
    component: () => import( /* webpackChunkName: "shoppingCart" */ '../views/QuicklyOders/QuicklyOders.vue')
  },
  {
    path: '/confrimShopping',
    name: 'ConfrimShopping',
    meta: {
      title: '确认订单信息',
      isTab: true,
      auth: true
    },
    component: () => import( /* webpackChunkName: "shoppingCart" */ '../views/ShoppingCart/ConfrimShopping.vue')
  },
  {
    path: '/user',
    redirect: '/user/myOrder',
    name: 'User',
    meta: {
      title: '个人中心',
      isTab: true,
      auth: true
    },
    component: () => import( /* webpackChunkName: "user" */ '../views/User/User.vue'),
    children: [{
        path: 'accountPeriod',
        name: 'AccountPeriod',
        meta: {
          title: '账期使用记录'
        },
        component: () => import( /* webpackChunkName: "AccountPeriod" */ '../components/User/accountPeriod/index.vue'),
      },
      {
        path: 'myPoints',
        name: 'MyPoints',
        meta: {
          title: '我的积分'
        },
        component: () => import( /* webpackChunkName: "MyPoints" */ '../components/User/MyPoints/index.vue'),
      },
      {
        path: 'preferential',
        name: 'Preferential',
        meta: {
          title: '我的慧金豆',
          isTab: false
        },
        component: () => import( /* webpackChunkName: "preferential" */ '../components/User/Preferential/index.vue')

      },
      {
        path: 'userinfo',
        name: 'userinfo',
        meta: {
          title: '账户信息',
          isTab: false
        },
        component: () => import( /* webpackChunkName: "preferential" */ '../components/User/Userinfo.vue')
      },
      {
        path: 'orderReport',
        name: 'orderReport',
        meta: {
          title: '订单报表',
          isTab: false
        },
        component: () => import( /* webpackChunkName: "preferential" */ '../components/User/orderReport/index.vue')
      },
      {
        path: 'billReport',
        name: 'billReport',
        meta: {
          title: '账单报表',
          isTab: false
        },
        component: () => import( /* webpackChunkName: "preferential" */ '../components/User/billReport/index.vue')
      },
      {
        path: 'myOrder',
        name: 'MyOrder',
        meta: {
          title: '我的订单'
        },
        component: () => import( /* webpackChunkName: "myOrder" */ '../components/User/MyOrder/index.vue'),
        children: [{
            path: 'orderDetail',
            name: 'OrderDetail',
            meta: {
              title: '订单详情'
            },
            component: () => import( /* webpackChunkName: "orderDetail" */ '../components/User/Order/OrderDetail.vue')
          },
          {
            path: 'afterseleDetail',
            name: 'AfterseleDetail',
            meta: {
              title: '售后详情'
            },
            component: () => import( /* webpackChunkName: "AfterseleDetail" */ '../components/User/Order/AfterseleDetail.vue')
          },
          {
            path: 'aftersele',
            name: 'Aftersele',
            meta: {
              title: '申请售后'
            },
            component: () => import( /* webpackChunkName: "Aftersele" */ '../components/User/Order/Aftersele.vue')
          },
        ]
      },
      {
        path: 'myInvoice',
        name: 'MyInvoice',
        meta: {
          title: '我的发票'
        },
        component: () => import( /* webpackChunkName: "myInvoice" */ '../components/User/MyInvoice/index.vue'),
        children: [{
          path: 'detail',
          name: 'InvoiceDetail',
          meta: {
            title: '发票详情'
          },
          component: () => import( /* webpackChunkName: "InvoiceDetail" */ '../components/User/MyInvoice/detail.vue')
        }, ]
      },
      {
        path: 'applyInvoice',
        name: 'ApplyInvoice',
        meta: {
          title: '申请发票'
        },
        component: () => import( /* webpackChunkName: "applyInvoice" */ '../components/User/applyInvoice/index.vue'),
        children: [{
          path: 'ApplyInvoiceConfirm',
          name: 'ApplyInvoiceConfirm',
          meta: {
            title: '查看添加结果'
          },
          component: () => import( /* webpackChunkName: "InvoiceDetail" */ '../components/User/applyInvoice/Confirm.vue')
        }, ]
      },
      {
        path: 'myLoan',
        name: 'MyLoan',
        meta: {
          title: '我的药采贷'
        },
        component: () => import( /* webpackChunkName: "myLoan" */ '../components/User/MyLoan/index.vue'),
        children: [{
            path: 'myLoanDetail',
            name: 'MyLoanDetail',
            meta: {
              title: '我的药采贷详情'
            },
            component: () => import( /* webpackChunkName: "MyLoanDetail" */ '../components/User/Loan/MyLoanDetail.vue'),
          },
          {
            path: 'myLoanOrderList',
            name: 'MyLoanOrderList',
            meta: {
              title: '药采贷订单详情'
            },
            component: () => import( /* webpackChunkName: "MyLoanOrderList" */ '../components/User/Loan/MyLoanOrderList.vue'),
          },
        ]
      },
      {
        path: 'myFavorites',
        name: 'MyFavorites',
        meta: {
          title: '我的收藏'
        },
        component: () => import( /* webpackChunkName: "myFavorites" */ '../components/User/MyFavorites/index.vue')
      },
      {
        path: 'myMessage',
        name: 'MyMessage',
        meta: {
          title: '我的消息'
        },
        component: () => import( /* webpackChunkName: "myMessage" */ '../components/User/MyMessage/index.vue')
      },
      {
        path: 'Aftersales',
        name: 'AfterSales',
        meta: {
          title: '售后管理'
        },
        component: () => import( /* webpackChunkName: "AfterSales" */ '../components/User/AfterSales/index.vue'),
        children: []
      },
      {
        path: 'resetPassword',
        name: 'ResetPassword',
        meta: {
          title: '重置支付密码'
        },
        component: () => import( /* webpackChunkName: "ResetPassword" */ '../components/User/resetPassword/index.vue'),
        children: []
      },
      {
        path: 'ProcurementPlanning',
        name: 'ProcurementPlanning',
        meta: {
          title: '我的采购计划'
        },
        component: () => import( /* webpackChunkName: "ProcurementPlanning" */ '../components/User/ProcurementPlanning/index.vue'),
        children: [{
          path: 'ProcurementDetail',
          name: 'ProcurementDetail',
          meta: {
            title: '审批详情'
          },
          component: () => import( /* webpackChunkName: "ProcurementDetail" */ '../components/User/Procurement/ProcurementDetail.vue')
        }, ]
      },
    ]
  },
  {
    path: '/qualifications',
    name: 'Qualifications',
    meta: {
      title: '公司资质',
      auth: true
    },
    component: () => import( /* webpackChunkName: "myFavorites" */ '../views/Qualifications/qualifications.vue')
  },

  {
    path: '/payAccountPeriod',
    name: 'PayAccountPeriod',
    meta: {
      title: '还款',
      auth: true
    },
    component: () => import( /* webpackChunkName: "free" */ '../components/User/accountPeriod/pay.vue')
  },
  {
    path: '/newPay',
    name: 'newPay',
    meta: {
      title: '付款页面',
      auth: true
    },
    component: () => import( /* webpackChunkName: "free" */ '../components/Pay/newPay.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


// 导航守卫
router.beforeEach((to, from, next) => {
  if (to.meta.auth) {
    let token = sessionStorage.getItem("token")
    if (token) {
      next()
    } else {
      next('/login');
      return
    }
  } else {
    next()
  }
})

// 解决Vue-Router升级导致的Uncaught(in promise) navigation guard问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

export default router