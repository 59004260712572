<template>
    <div class="register-box">
        <div class="notice-box">
            <div>新账号开通成功赠送<span class="red">“{{ruleInfo.amt}}慧金豆”</span></div>
            <div> 注：慧金豆可用于抵扣订单支付金额<span style="color: #26C487">（仅限药釆贷专区商品）</span></div>
            <div class="time-box">活动日期：{{ruleInfo.beginDate}}--{{ruleInfo.endDate}}</div>
        </div>
        <div class="info-box">
            <div class="title">注册</div>
            <el-form ref="form" :model="form" :rules="formRules">
                <el-form-item label="姓名" prop="applyName">
                    <el-input class="half" v-model="form.applyName" placeholder="请输入您的姓名" clearable maxlength="16"></el-input>
                    <el-radio-group class="half" v-model="form.sex">
                        <el-radio :label="1">先生</el-radio>
                        <el-radio :label="2">女士</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="手机" prop="applyPhone">
                    <el-input v-model="form.applyPhone" placeholder="请输入您的联系方式" clearable maxlength="11"></el-input>
                </el-form-item>
                <el-form-item label="上传营业执照" prop="idcardImage">
                    <el-upload
                        class="uploader"
                        :action="imgUploadUrl"
                        :show-file-list="false"
                        :on-success="UploadSuccess"
                        :before-upload="checkUploadType">
                        <img v-if="form.idcardImage" :src="form.idcardImage" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <div class="uploader" v-for="(item,index) in demoUrl" :key="index" @click="zoomPic()">
                        <img class="example-img" :src="item" alt="">
                        <div class="example-notice">示例</div>
                        <div class="example-zoom">
                            <i class="el-icon-search"></i>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="营业执照号" prop="idcardNo">
                    <el-input v-model="form.idcardNo" placeholder="请输入上传的营业执照号" clearable maxlength="30"></el-input>
                </el-form-item>
                <el-form-item label="留言">
                    <el-input v-model="form.memo" type="textarea" :rows="2" placeholder="请输入您的留言" clearable maxlength="50" show-word-limit></el-input>
                </el-form-item>
                <el-button class="button" :loading="loading" @click="submitForm('form')">注册</el-button>
            </el-form>
            <div class="new-box" @click="goLogin">
                <span>已有帐号，直接登录</span>
                <span class="new-box-icon">></span>
            </div>
            <div class="protocol">
                <el-radio v-model="readIcon" label="1">
                    已阅读并同意
                    <span class="protocol-link" @click="read">《慧金采平台服务协议》</span>
                </el-radio>
            </div>
            <div class="service">
                <img class="icon14 mr5" src="@/assets/images/login/erji.png" alt />
                <span class="mr5">如有疑问,联系客服:</span>
                <span class="gr">028-61622080</span>
            </div>
        </div>
    </div>
</template>

<script>
import {getRegisterRule, registerApply, getIdcard} from '../../../request/Login/loginApi'
export default {
    data(){
        return{
            ruleInfo:{},
            form:{
                source:'PC',
                sex: 1,
                idcardNo:''
            },
            formRules:{
                applyName: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                    {pattern:/[^`~!@#$%^&*_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*——\-+={}|《》？：“”【】、；‘'，。、]/, message: "姓名不能有特殊字符", trigger: "change",},
                ],
                applyPhone: [
                    { required: true, message: '请输入联系方式', trigger: 'blur' },
                    {
                        pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                        message: "请输入正确的手机号码",
                        trigger: "blur",
                    },
                ],
                idcardImage: [
                    { required: true, message: '请上传营业执照', trigger: 'blur' },
                ],
                idcardNo: [
                    { required: true, message: '请输入营业执照号', trigger: 'blur' },
                ],
            },
            imageUrl: '',
            demoUrl:[require('../../../assets/images/login/example.png')],
            //  图片上传地址
            imgUploadUrl: process.env.VUE_APP_BASE_URL + "oss/outside/sys/oss/upload",
            readIcon:'0',
            loading: false
        }
    },
    created() {
        this.getRule();
    },
    methods:{
        read() {
            window.open(`/agreement.html`);
        },
        goLogin(){
            this.$emit('goLogin',0)
        },
        zoomPic(item){
            const $viewer = this.$viewerApi({
                images: this.demoUrl
            })
        },
        // 获取拉新活动内容
        getRule(){
            getRegisterRule().then(res => {
                if(res.code === 200) {
                    this.ruleInfo = res.data
                    this.form.ruleId = res.data.id
                }
            })
        },
        //图片上传成功返回
        UploadSuccess(res) {
            if (res.code == 200) {
                this.form.idcardImage = res.data.src
                this.$message.success("图片上传成功");
                this.getCard(res.data.src);
                this.$forceUpdate();
            } else {
                this.$message.warning(res.msg);
            }
        },
        //  限制上传文件类型
        checkUploadType(file) {
            let imgType = file.type;
            if (
                !(
                imgType === "image/jpeg" ||
                imgType === "image/png" ||
                imgType === "image/jpg"
                )
            ) {
                this.$message.warning("文件类型错误,请上传图片");
                return false;
            }
        },
        getCard(data){
            getIdcard(data).then(res => {
                if(res.code === 200) {
                    this.form.idcardNo = res.data
                    this.$forceUpdate()
                }
            })
        },
        // 注册
        submitForm(formName) {
            if(this.readIcon == 0){
                this.$message.warning('请先阅读服务协议!')
                return
            }
            
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.loading = true
                    registerApply(this.form).then(res => {
                        if(res.code === 200) {
                            this.$message.success('申请注册成功!');
                            setTimeout(() => {
                                this.goLogin()
                            },1000)
                        }
                        this.loading = false
                    })
                } else {
                    return false;
                }
            });
        }
    }
}
</script>

<style lang="less" scoped>
.register-box {
    position: relative;
    height: 800px;
    background: url("../../../assets/images/login/register.png") no-repeat;
    background-size: cover;
    background-position: center;
    .notice-box {
        position: relative;
        top: 550px;
        left: 400px;
        width: 400px;
        text-align: center;
        line-height: 24px;
    }
    .time-box {
        width: 270px;
        height: 46px;
        background: #F9D498;
        border-radius: 36px;
        font-size: 14px;
        font-weight: 500;
        color: #EC5838;
        text-align: center;
        line-height: 46px;
        margin: 20px auto 0;
    }
    .red {
        font-size: 18px;
        color: #FF1A2E;
    }

    .info-box {
        width: 420px;
        height: 752px;
        background: #FFFFFF;
        border-radius: 10px;
        position: absolute;
        top: 10px;
        right: 140px;
        text-align: center;
        padding-top: 28px;
        .title {
            font-size: 18px;
            font-weight: bold;
            color: #333333;
        }
    }
}

.new-box {
  text-align: right;
  color: #666666;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-right: 35px;
  .new-box-icon {
    display: inline-block;
    width: 18px;
    height: 18px;
    background: #E6E6E6;
    border-radius: 50%;
    text-align: center;
    line-height: 18px;
    color: #2B2F33;
    margin-left: 8px;
  }
}
.protocol {
    height: 38px;
    background: #F2FCF5;
    font-size: 14px;
    color: #adafad;
    text-align: center;
    line-height: 38px;
    &-link {
        color: #26c487;
        cursor: pointer;
        &:hover {
        color: #009b60;
        }
    }
}
.service {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    line-height: 56px;
    font-size: 14px;
}
.el-form-item {
    padding: 0 38px;
    margin-bottom: 10px;
}
/deep/ .el-form-item__label{
    float:none;
    display: block;
    width: 100%;
    text-align: left;
}
/deep/ .el-form-item__content {
    display: flex;
    align-items: center;
}
.half {
    width: 50%;
}

.uploader {
    width: 90px;
    height: 90px;
    border: 1px solid #E6ECF0;
    font-size: 20px;
    line-height: 90px;
    margin-right: 12px;
    position: relative;
    cursor: pointer;
    :deep(.el-upload) {
        width: 100%;
    }
    .example-img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .example-notice {
        width: 50px;
        height: 22px;
        background: #FFBB0B;
        border-radius: 3px;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
        line-height: 22px;
        position: absolute;
        top: 30px;
        left: 20px;
    }
    .example-zoom {
        width: 24px;
        height: 18px;
        background: rgba(0,0,0,0.5);
        border-radius: 0px 3px 0px 3px;
        line-height: 18px;
        font-size: 16px;
        color: #ffffff;
        position: absolute;
        top: 0;
        right: 0;
    }
    .avatar {
        width: 90px;
        height: 90px;
    }
}
.button {
    width: 345px;
    height: 50px;
    background: #26C487;
    font-size: 16px;
    color: #ffffff;
    margin: 18px 0;
}

@media screen and (max-width: 1680px) {
    .register-box {
        .notice-box {
            left: 300px
        }
    }
}
@media screen and (max-width: 1440px) {
    .register-box {
        .notice-box {
            left: 180px
        }
    }
}
</style>