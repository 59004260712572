<!--
 * @Description: description
 * @Author: booshaw
 * @Github: github
 * @Date: 2022-06-08 11:53:34
 * @LastEditors: booshaw
 * @LastEditTime: 2022-06-10 08:57:56
-->
<template>
  <div>
    <div class="account-wrapper">
      <CustomInput v-model="signConfig.username" label="账号" placeholder="请输入账号"></CustomInput>
      <CustomInput v-model="signConfig.password" type="password" label="密码" placeholder="请输入账号密码" @key-up-enter="handleSign"></CustomInput>
    </div>
    <el-checkbox v-model="signConfig.rememberPassword" class="keep-password" @change="toggleRememberPassword">记住密码</el-checkbox>
    <el-button class="sign-button" :loading="loading" @click="handleSign">登录</el-button>
  </div>
</template>

<script type="text/ecmascript-6">
import CustomInput from '@/components/custom-base/custom-input/index.vue'
import { LoginApi } from "@/request/Login/loginApi";

export default {
  components: {
    CustomInput
  },
  data() {
    return {
      signConfig: JSON.parse(localStorage.getItem('signConfig')) || {
        username: '',
        password: '',
        rememberPassword: false,
      },
      disabled: true,
      loading: false
    }
  },
  computed: {
    disableSign() {
      return !this.signConfig.username && !this.signConfig.password
    }
  },
  methods: {
    async handleSign() {
      if (this.disableSign) {
        this.$message.warning('请输入账户和密码')
        return
      }
      this.loading = true
      try {
        const { code, data } = await LoginApi({
          username: this.signConfig.username,
          password: this.signConfig.password
        })
        if (code === 200) {
          this._clearForm()
          this.setSignConfig()
          sessionStorage.setItem("token", data.token);
          this.$router.push('/home');
        }
      } catch(error) {
        this.$message.error(error)
      }
       finally {
        this.loading = false
      }
    },
    _clearForm() {
      this.signConfig.username = ''
      this.signConfig.password = ''
    },
    toggleRememberPassword(e) {
      e ? this.setSignConfig() : this.removeSignConfig()
    },
    setSignConfig() {
      localStorage.setItem('signConfig', JSON.stringify(this.signConfig))
    },
    removeSignConfig() {
      localStorage.removeItem('signConfig')
    }
  }
}
</script>

<style scoped lang="less">
@import '../../../assets/base.less';
::v-deep{
  .input-wrapper-content .focus[data-v-b2890fba] {
    height: 46px;
    line-height: 48px;
}
}
.account-wrapper {
  position: relative;
  width: 100%;
  .sms-code {
    color: #26c487;
    font-size: 16px;
    cursor: pointer;
    border: none;
    outline: none;
    background: transparent;
  }
  .disabled {
    color: #adafad;
    cursor: not-allowed;
  }
}
.sign-button {
  margin: 26px auto;
  width: 100%;
  height: 50px;
  background: #26c487;
  color: #ffffff;
  font-size: 16px;
  text-align: center;
}
</style>
