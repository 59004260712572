<template>
    <div>
        <div class="box" :class="show?'':'none'" :style="{backgroundImage:`url(${backgroundImg})`}" @click="showDialog">
            <i class="el-icon-error" @click.stop="close"></i>
        </div>
        <LoanApply :dialog-visible="dialogVisible" @close="closeLoan"/>
    </div>
</template>

<script>
import LoanApply from './loanApply.vue';
export default{
    components:{
        LoanApply
    },
    data(){
        return {
            show:true,
            dialogVisible: false,
            backgroundImg: null
        }
    },
    created() {
        const src = sessionStorage.getItem('topBack')
        if (src) {
            this.backgroundImg = src
        } else {
            this.show = false;
        }
    },
    methods:{
        close(){
            this.show = false;
        },
        showDialog() {
            const token = sessionStorage.getItem('token')
            if (token) {
                this.dialogVisible = true
            } else {
                this.$confirm('申请药采贷需要登录, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$router.push({path:"/login", query:{type: 0}});
                }).catch(() => {});
            }
        },
        closeLoan(value) {
            this.dialogVisible = value
        }
    }
}
</script>

<style lang="less" scoped>
.box {
    width: 100%;
    height: 130px;
    //background-image: url(../assets/images/index-top.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
    position: relative;
    i { 
        position: absolute;
        top: 15px;
        right: 15px;
        z-index: 9;
        font-size: 22px;
    }
}
.none {
    display: none;
}
</style>