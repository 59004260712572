<template>
  <div
    class="top"
    :class="$route.path === '/home' ? 'home-header' : 'inner-header'"
  >
    <header class="header">
      <div class="flex-between">
        <el-popover v-model="showCustomerList" trigger="click" placement="bottom-start">
          <div class="position-content">
            <div style="margin-bottom: 10px" class="fs14">选择客户名称</div>
            <el-input
              @keyup.enter.native="shearchAddr"
              style="height: 34px"
              placeholder="请输入客户名称"
              :suffix-icon="!inputAddr ? 'el-icon-search' : ''"
              v-model="inputAddr"
              clearable
            ></el-input>

            <div
              v-for="(item, index) in userAddrs"
              :key="index"
              @click.prevent="changeAdress(index, item)"
              class="li flex-between cursor"
              :class="item.id == checked ? 'active' : ''"
            >
              <div class="left">
                <img
                  src="../assets/images/header/store.png"
                  width="13px"
                  height="12px"
                  alt
                />
                <div class="fs14 mt10">{{ item.name }}</div>
                <div class="fs12 mt10" style="color: #adafad">
                  {{ item.address }}
                </div>
              </div>
              <div class="right flex-end">
                <el-radio v-model="checked" :label="item.id">&nbsp;</el-radio>
              </div>
            </div>
          </div>
          <div slot="reference" class="position cursor">
            <i class="el-icon-location mr8"></i>
            {{ this.checkedName }}
            <i class="el-icon-arrow-down ml6"></i>
          </div>
        </el-popover>
       <!--  <div v-if="token" class="cursor new-button" @click="dialogVisible = true">新门店注册</div> -->
      </div>
      
      <ul class="flex-end">
        <li>
          Hi,{{ userInfo.nickName || "" }}欢迎你!
          <div v-if="token" @click="clickOut" style="color: #adafad" class="cup">
            【退出】
          </div>
          <!-- <div v-else @click="goRegister" style="margin-left: 18px" class="cup">
            注册
          </div> -->
        </li>
        <li class="home-btn" @click="goHome">首页</li>
        <div class="su"></div>
        <li v-if="!token" style="color: #26C487" @click="goLogin">登录</li>
        <div class="su"></div>
        <li
          @click="$router.push('/user/myOrder')"
          :class="{ green: $route.path === '/user/myOrder' }"
        >
          个人中心
        </li>
        <div class="su"></div>
        <li
          @click="$router.push('/quicklyOders')"
          :class="{ green: $route.path === '/quicklyOders' }"
        >
          快速下单
        </li>
        <div class="su"></div>
        <li
          @click="$router.push('/qualifications')"
          :class="{ green: $route.path === '/qualifications' }"
        >
          公司资质
        </li>
        <div class="su"></div>
        <li @click="active = 8" :class="'green' == 8 ? 'green' : ''">
          <el-popover placement="top" trigger="hover">
            <div class="center">
              <img
                src="@/assets/images/header/officialAccount.jpg"
                style="width: 115px; height: 115px"
              />
            </div>

            <div class="saoma mt5">扫码关注<span class="gr">慧金采</span></div>
            <div slot="reference" class="flex-center cursor">
              <img
                style="width: 12px; height: 13px; margin-right: 5px"
                src="../assets/images/header/wchat.png"
                alt
              />
              <span>微信公众号</span>
              <img
                style="width: 7px; height: 4px; margin-left: 5px"
                src="../assets/images/header/down.png"
                alt
              />
            </div>
          </el-popover>
        </li>
      </ul>
    </header>
    <el-dialog class="dia" title="提示" :visible.sync="closeSure" width="360px">
      <div class="tac">是否确认退出登录?</div>
      <span slot="footer" class="dialog-footer">
        <el-button style="margin-right: 60px" @click="closeSure = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="sureQuit">确 定</el-button>
      </span>
    </el-dialog>

    <Register :dialog-visible="dialogVisible" @close="closeDialog" />
  </div>
</template>

<script>
import {
  info,
  logout,
  shoppingMall,
  save,
  mallCusStaff,
} from "@/request/users/users";
import { findProductList } from "@/request/CartApi/cart";
import Register from "./register.vue";
export default {
  components:{
    Register
  },
  created() {
    this.token = sessionStorage.getItem('token')
    if (this.token) {
      //获取地址
      this.getAddr();
      //用户信息
      info().then((res) => {
        if (res.code === 200) {
          this.userInfo = res.data;
          sessionStorage.setItem("setOwerId", res.data.id);
          this.$store.commit("setOwerId", res.data.id);
          //药采贷权限
          if (res.data.permissions.indexOf("mall:pay:loan") !== -1) {
            // this.$store.commit("loan", true);
            localStorage.setItem("isloan", "true");
          } else {
            // this.$store.commit("loan", false);
            localStorage.removeItem("isloan");
          }
          //我的积分权限
          if (res.data.permissions.indexOf("mall:pay:integral") !== -1) {
            // this.$store.commit("myPoint", true);
            localStorage.setItem("myPoint", "true");
          } else {
            // this.$store.commit("myPoint", false);
            localStorage.removeItem("myPoint");
          }
          //对公支付
          if (res.data.permissions.indexOf("mall:pay:com") !== -1) {
            // this.$store.commit("myPoint", true);
            localStorage.setItem("Paycom", "true");
          } else {
            // this.$store.commit("myPoint", false);
            localStorage.removeItem("Paycom");
          }
        }
      });
    }
  },
  data() {
    return {
      //用户地址
      userAddr: [],
      //第一次获取地址默认第一个
      firstin: 1,
      //退出登录
      closeSure: false,
      //收货地址
      inputAddr: null,
      checked: "0",
      active: 0,
      flag: true,
      checkedName: "",
      cartTotal: 0,
      userInfo: {
        nickName: "",
      },
      userAddrs: [],

      token:'',
      dialogVisible: false,
      showCustomerList: false
    };
  },
  methods: {
    //搜索收货地址
    shearchAddr() {
      this.getAddr();
    },
    //获取用户收货地址
    getAddr() {
      shoppingMall({ name: this.inputAddr }).then((res) => {
        if (res.code === 200 && res.data.length > 0) {
          this.userAddrs = res.data;
          this.$store.commit("saveuserAddr", res.data);
          sessionStorage.setItem("AddrId", res.data[0].id);
          if (this.firstin == 1) {
            localStorage.setItem("customerList", JSON.stringify(res.data[0]));
            console.log(res.data[0], "res.data[0]");
            //
            sessionStorage.setItem("AddrId", res.data[0].id);
            this.$store.commit("changeAddrId", res.data[0].id);
            this.$store.dispatch("dispatchchangeAddrId", res.data[0].id);
            this.checked = res.data[0].id;
            this.checkedName = res.data[0].name;
            this.$store.commit("setcustmerAddname", this.checkedName);
            this.$store.dispatch("changeCustmerAddname", this.checkedName);
            this.firstin++;
          }
          mallCusStaff({ cusAddrId: this.checked }).then((res) => {
            if (res.code === 200) {
              this.$store.commit("setmallCusStaff", res.data);
            }
          });
          findProductList({
            customerId: this.$store.state.AddrId,
          }).then((res) => {
            if (res.code === 200) {
              let productNum = res.data.productList.length;
              this.$store.commit("setProductNum", productNum);
            }
          });
        } else {
          // localStorage.removeItem('token')
          // this.$router.push('login')
          sessionStorage.removeItem("AddrId");
          this.$store.commit("changeAddrId", null);
        }
      });
    },
    sureQuit() {
      this.closeSure = false;
      this.$message.success("退出成功");
      localStorage.removeItem("myPoint");
      localStorage.removeItem("isloan");
      localStorage.removeItem("Paycom");
      //退出登录接口
      logout().then((res) => {});
      setTimeout(() => {
        sessionStorage.removeItem("token");
        this.$router.push("/login");
      }, 500);
    },
    //点击退出按钮
    clickOut() {
      this.closeSure = true;
      // $router.push('/login')
    },
    //切换客户地址
    changeAdress(index, item) {
      this.checked = item.id;
      this.checkedName = item.name;
      localStorage.setItem("customerList", JSON.stringify(item));
      console.log(item, "itemm");
      this.$store.dispatch("changeCustmerAddname", this.checkedName);
      this.$store.commit("changeAddrId", item.id);
      this.$message.success("修改地址成功");
      save({ customerAddrId: item.id }).then((res) => {
        if (res.code === 200) {
          this.getAddr();
          this.showCustomerList = false;
        }
      });

      findProductList({
        customerId: this.$store.state.AddrId,
      }).then((res) => {
        if (res.code === 200) {
          let productNum = res.data.productList.length;
          this.$store.commit("setProductNum", productNum);
        }
      });
    },

    // 切换登录页
    goLogin(){
      this.$router.push({path:"/login", query:{type: 0}});
    },
    goHome() {
      this.$router.push({path:"/home"});
    },
    goRegister(){
      this.$router.push({path:"/login", query:{type: 1}});
    },
    closeDialog(value) {
        this.dialogVisible = value
    }
  },
  watch: {},
};
</script>

<style lang="less" scoped>
@import "../assets/base.less";
::v-deep {
  .el-input__suffix {
    height: 40px;
  }
  .el-popover {
    min-width: initial;
  }
  .el-dialog__footer {
    padding: 20px;
    padding-top: 10px;
    display: flex;
    justify-content: space-around;
    box-sizing: border-box;
  }
  .el-badge__content.is-fixed {
    position: absolute;
    top: 0;
    right: 0px;
    transform: translateY(-50%) translateX(100%);
  }
  .el-badge__content,
  .el-progress.is-exception .el-progress-bar__inner {
    background-color: #e33233;
  }
}
.su {
  margin-top: 5px;
  line-height: 25px;
  width: 1px;
  height: 15px;
  background: #e6e6e6;
  opacity: 1;
  border-radius: 1px;
}
.position {
  position: relative;
  padding: 0 18px;
  box-sizing: border-box;
  box-shadow: 2px 1px 4px -2px rgba(165, 165, 165, 0.08),
    2px 0 4px -2px rgba(165, 165, 165, 0.08);
}
.position-content {
  padding: 10px;
  min-width: 354px;
  max-height: 500px;
  overflow-x: hidden;
  background-color: #fff;
  box-sizing: border-box;
  .li {
    margin-top: 30px;
    box-sizing: border-box;
    padding: 20px 0 20px 16px;
    width: 100%;
    /* height: 105px; */
    background: #ffffff;
    border-radius: 6px 6px 6px 6px;
    border: 1px solid @hui;
    box-sizing: border-box;
    .right {
      flex: 0 0 40px;
    }
  }
  .active {
    border: 1px solid #26c487;
  }
}
.center {
  display: flex;
  justify-content: center;
}
.saoma {
  letter-spacing: 3px;
  text-align: center;
  font-size: 12px;
  font-family: PingFang HK-Regular, PingFang HK;
  font-weight: 400;
  color: #333333;
}
.top {
  width: 100%;
  font-size: 14px;
  color: #646464;
  margin: 0 auto;
  .header {
    display: flex;
    justify-content: space-between;
    height: 40px;
    align-items: center;
    font-size: 14px;
    width: 1400px;
    margin: 0 auto;
    @media screen and(max-width: 1400px) {
      width: 100%;
      min-width: 1200px;
      width: 1200px;
    }
    .icon-position {
      width: 10px;
      height: 10px;
      padding-right: 10px;
    }
    ul {
      .green {
        color: @green;
      }
      li {
        display: flex;
        align-items: center;
        margin: 0 20px;
        cursor: pointer;
        font-size: 14px;
        &:last-child {
          margin-right: 0;
        }
        &.avatar-li {
          display: flex;
          align-items: center;
          img {
            width: 26px;
            border-radius: 50%;
            margin-right: 8px;
          }
        }
      }
      .header_btn {
        width: 124px;
        height: 40px;
        /* background: @orange; */
        display: flex;
      }
      .cart_btn {
        justify-content: center;
        line-height: 22px;
      }

      .login_btn {
        justify-content: center;
        span {
          margin-left: 3px;
          margin-right: 3px;
        }
        b {
          height: 22px;
          color: #ff2832;
          border-radius: 11px;
          text-align: center;
          line-height: 22px;
        }
      }
    }
  }
}
.home-header {
  background: #f9f9f9;
}
.inner-header {
  background: #ffffff;
  border-bottom: 1px solid #e7ebe7;
}

.new-button {
  width: 109px;
  height: 29px;
  background: #FF5237;
  box-shadow: inset 0px -3px 4px 0px rgba(181,27,28,0.6);
  border-radius: 4px;
  color: #ffffff;
  text-align: center;
  line-height: 29px;
}

.home-btn {
  color:#26c487;
  padding: 4px 8px;
  border: 1px solid #26c487;
  border-radius: 20px;
}
</style>
