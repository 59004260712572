<template>
    <el-dialog :visible.sync="dialogVisible" width="485px" :close-on-click-modal="false">
        <div class="content">
            <div class="title">
                <span>注册</span>
                <i class="el-icon-close" @click="closeDialog"></i>
            </div>
            <div class="info">
                <div class="give">新账号开通成功会赠送 “{{ruleInfo.amt}}慧金豆”</div>
                <div>慧金豆可用于抵扣订单支付金额<span style="color:#26C487">（仅限药釆贷专区商品）</span></div>
                <div class="time">活动日期：{{ruleInfo.beginDate}}--{{ruleInfo.endDate}}</div>
                <img src="../assets/images/login/dialog-gift.png" alt="">
            </div>
            <el-form ref="form" :model="form" :rules="formRules">
                <el-form-item label="姓名" prop="applyName">
                    <el-input class="half" v-model="form.applyName" placeholder="请输入您的姓名" clearable maxlength="16"></el-input>
                    <el-radio-group class="half" v-model="form.sex">
                        <el-radio :label="1">先生</el-radio>
                        <el-radio :label="2">女士</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="手机" prop="applyPhone">
                    <el-input v-model="form.applyPhone" placeholder="请输入您的联系方式" clearable maxlength="11"></el-input>
                </el-form-item>
                <el-form-item label="上传营业执照" prop="idcardImage">
                    <el-upload
                        class="uploader"
                        :action="imgUploadUrl"
                        :show-file-list="false"
                        :on-success="UploadSuccess"
                        :before-upload="checkUploadType">
                        <img v-if="form.idcardImage" :src="form.idcardImage" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <div class="uploader" v-for="(item,index) in demoUrl" :key="index" @click="zoomPic()">
                        <img class="example-img" :src="item" alt="">
                        <div class="example-notice">示例</div>
                        <div class="example-zoom">
                            <i class="el-icon-search"></i>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="营业执照号" prop="idcardNo">
                    <el-input v-model="form.idcardNo" placeholder="请输入上传的营业执照号" clearable maxlength="30"></el-input>
                </el-form-item>
                <el-form-item label="留言">
                    <el-input v-model="form.memo" type="textarea" :rows="2" placeholder="请输入您的留言" clearable maxlength="50" show-word-limit></el-input>
                </el-form-item>
                <el-button class="button" :loading="loading" @click="submitForm('form')">注册</el-button>
                <div class="protocol">
                    <el-radio v-model="readIcon" label="1">
                        已阅读并同意
                        <span class="protocol-link" @click="read">《慧金采平台服务协议》</span>
                    </el-radio>
                </div>
            </el-form>
        </div>
    </el-dialog>
</template>

<script>
import {getRegisterRule, registerApply, getIdcard} from '../request/Login/loginApi'
export default{
    props:['dialogVisible'],
    data(){
        return{
            ruleInfo:{},
            form:{
                source:'PC',
                sex: 1,
                idcardNo: ''
            },
            formRules:{
                applyName: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                    {pattern:/[^`~!@#$%^&*_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*——\-+={}|《》？：“”【】、；‘'，。、]/, message: "姓名不能有特殊字符", trigger: "change",},
                ],
                applyPhone: [
                    { required: true, message: '请输入联系方式', trigger: 'blur' },
                    {
                        pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                        message: "请输入正确的手机号码",
                        trigger: "blur",
                    },
                ],
                idcardImage: [
                    { required: true, message: '请上传营业执照', trigger: 'blur' },
                ],
                idcardNo: [
                    { required: true, message: '请输入营业执照号', trigger: 'blur' },
                ],
            },
            imageUrl: '',
            demoUrl:[require('../assets/images/login/example.png')],
            //  图片上传地址
            imgUploadUrl: process.env.VUE_APP_BASE_URL + "oss/outside/sys/oss/upload",
            readIcon:'0',
            loading: false
        }
    },
    created() {
        if (sessionStorage.getItem('token')) {
            this.getRule();
        }
    },
    methods:{
        read() {
            window.open(`/agreement.html`);
        },
        goLogin(){
            this.$emit('goLogin',0)
        },
        zoomPic(item){
            const $viewer = this.$viewerApi({
                images: this.demoUrl
            })
        },
        // 获取拉新活动内容
        getRule(){
            getRegisterRule().then(res => {
                if(res.code === 200) {
                    this.ruleInfo = res.data
                    this.form.ruleId = res.data.id
                }
            })
        },
        //图片上传成功返回
        UploadSuccess(res) {
            if (res.code == 200) {
                this.form.idcardImage = res.data.src
                this.$message.success("图片上传成功");
                this.getCard(res.data.src)
                this.$forceUpdate();
            } else {
                this.$message.warning(res.msg);
            }
        },
        //  限制上传文件类型
        checkUploadType(file) {
            let imgType = file.type;
            if (
                !(
                imgType === "image/jpeg" ||
                imgType === "image/png" ||
                imgType === "image/jpg"
                )
            ) {
                this.$message.warning("文件类型错误,请上传图片");
                return false;
            }
        },
        getCard(data){
            getIdcard(data).then(res => {
                if(res.code === 200) {
                    this.form.idcardNo = res.data
                    this.$forceUpdate()
                }
            })
        },
        // 注册
        submitForm(formName) {
            if(this.readIcon == 0){
                this.$message.warning('请先阅读服务协议!')
                return
            }
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.loading = true
                    registerApply(this.form).then(res => {
                        if(res.code === 200) {
                            this.$message.success('申请注册成功!');
                            setTimeout(() => {
                                this.goLogin()
                            },1000)
                        }
                        this.loading = false
                    })
                } else {
                    return false;
                }
            });
        },

        closeDialog() {
            this.$emit('close',false)
        }
    }
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog {
    border-radius: 10px;
}
/deep/ .el-dialog__header {
    display: none;
}
/deep/ .el-dialog__body {
    padding: 0;
}
.content {
    width: 100%;
    height: 852px;
    background-image: url(../assets/images/login/register-dialog.png);
    background-repeat: no-repeat;
    background-size: 100%;
    .title {
        font-size: 18px;
        font-weight: 500;
        color: #ffffff;
        padding-top: 25px;
        font-family: 'regular';
        text-align: center;
        i {
            float: right;
            margin-right: 26px;
            cursor: pointer;
        }
    }
    .info {
        width: 409px;
        height: 103px;
        background: #FEF3D8;
        border-radius: 10px;
        margin: 43px auto 9px;
        text-align: center;
        font-size: 13px;
        font-family: 'regular';
        font-weight: 400;
        color: #595A6D;
        padding-top: 15px;
        position: relative;
        .give {
            font-size: 15px;
            font-family: 'medium';
            color: #FF1A2E;
            margin-bottom: 5px;
        }
        .time {
            width: 230px;
            height: 26px;
            background: #FFDDA6;
            border-radius: 36px;
            font-size: 12px;
            color: #EC5838;
            text-align: center;
            line-height: 26px;
            margin: 9px auto 0;
        }
        img {
            width: 70px;
            height: 70px;
            position: absolute;
            left: -10px;
            top: -45px;
        }
    }
}

.el-form {
    width: 407px;
    height: 610px;
    border-radius: 10px;
    background: #FFFFFF;
    margin: 0 auto;
    padding: 0 31px;
}
.el-form-item {
    margin-bottom: 5px;
}
/deep/ .el-form-item__label{
    float:none;
    display: block;
    width: 100%;
    text-align: left;
}
/deep/ .el-form-item__content {
    display: flex;
    align-items: center;
}
.half {
    width: 50%;
}
.el-radio-group {
    margin-left: 10px;
}
.uploader {
    width: 90px;
    height: 90px;
    border: 1px solid #E6ECF0;
    font-size: 20px;
    line-height: 90px;
    margin-right: 12px;
    position: relative;
    cursor: pointer;
    text-align: center;
    :deep(.el-upload) {
        width: 100%;
    }
    .example-img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .example-notice {
        width: 50px;
        height: 22px;
        background: #FFBB0B;
        border-radius: 3px;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
        line-height: 22px;
        position: absolute;
        top: 30px;
        left: 20px;
    }
    .example-zoom {
        width: 24px;
        height: 18px;
        background: rgba(0,0,0,0.5);
        border-radius: 0px 3px 0px 3px;
        line-height: 18px;
        font-size: 16px;
        color: #ffffff;
        position: absolute;
        top: 0;
        right: 0;
    }
    .avatar {
        width: 90px;
        height: 90px;
    }
}
.button {
    width: 345px;
    height: 50px;
    background: #26C487;
    font-size: 16px;
    color: #ffffff;
    margin: 18px 0;
}
.protocol {
    width: 100%;
    height: 38px;
    text-align: center;
    line-height: 38px;
    .protocol-link {
        color: #26C487;
    }
}
</style>