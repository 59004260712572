<template>
  <div class="">
    <div class="customer-serve">
      <div class="header">
        <div class="df aic left">
          <img
            style="margin-right: 8px"
            src="@/assets/images/mallCusStaff/house.png"
            alt=""
          />
          <span class="txt">{{ $store.state.custmerAddname }}</span>
        </div>
        <div class="right cup">
          <i @click="callFather" class="el-icon-close" />
        </div>
      </div>
      <div v-if="isgoods" class="warring">
        <img
          class="icon"
          src="@/assets/images/mallCusStaff/warring.png"
          alt=""
        />
        该商品不可购买，请联系客服
      </div>
      <div v-if="$store.state.mallCusStaff.length > 0" class="mid">
        <div
          v-if="
            $store.state.mallCusStaff.some((item) => {
              return item.identity == 1;
            })
          "
          class="list"
        >
          <div @click="firstDown = !firstDown" class="cup person">
            <div class="df aic">
              <img
                class="icon"
                src="@/assets/images/mallCusStaff/yewu.png"
                alt=""
              />
              <span>{{
                $store.state.mallCusStaff[0].identity == 1
                  ? $store.state.mallCusStaff[0].staffName
                  : $store.state.mallCusStaff[1].staffName
              }}</span
              >&nbsp;<span>业务专员</span>
            </div>
            <i
              style="margin-top: 2px"
              :class="firstDown ? ' el-icon-arrow-up' : ' el-icon-arrow-down'"
            ></i>
          </div>
          <ul v-show="firstDown" class="mt10">
            <li class="person-item">
              <div class="left">
                <div class="phone">手机:</div>
                <div class="number">
                  {{
                    $store.state.mallCusStaff[0].identity == 1
                      ? $store.state.mallCusStaff[0].phone
                      : $store.state.mallCusStaff[1].phone
                  }}
                </div>
              </div>
              <img
                class="icon"
                src="@/assets//images/mallCusStaff/phone.png"
                alt=""
              />
            </li>
            <!-- <li class="person-item">
              <div class="left">
                <div class="phone">座机:</div>
                <div class="number">18380455540</div>
              </div>
               <img class="icon" src="@/assets//images/mallCusStaff/call.png" alt="">
            </li>
            <li class="person-item">
              <div class="left">
                <div class="phone">微信:</div>
                <div class="number">18380455540</div>
              </div>
               <img class="icon" src="@/assets//images/mallCusStaff/wechat.png" alt="">
            </li>
            <li class="person-item">
              <div class="left">
                <div class="phone">QQ:</div>
                <div class="number">18380455540</div>
              </div>
               <img class="icon" src="@/assets//images/mallCusStaff/qq.png" alt="">
            </li> -->
          </ul>
        </div>
        <div
          v-if="
            $store.state.mallCusStaff.some((item) => {
              return item.identity == 2;
            })
          "
          class="list"
        >
          <div @click="firstDown = !firstDown" class="cup person">
            <div class="df aic">
              <img
                class="icon"
                src="@/assets/images/mallCusStaff/jingli.png"
                alt=""
              />
              <span>
                {{
                  $store.state.mallCusStaff[0].identity == 2
                    ? $store.state.mallCusStaff[0].staffName
                    : $store.state.mallCusStaff[1].staffName
                }}</span
              >&nbsp;<span>业务经理</span>
            </div>
            <i
              style="margin-top: 2px"
              :class="!firstDown ? ' el-icon-arrow-up' : ' el-icon-arrow-down'"
            ></i>
          </div>
          <ul v-show="!firstDown" class="mt10">
            <li class="person-item">
              <div class="left">
                <div class="phone">手机:</div>
                <div class="number">
                  {{
                    $store.state.mallCusStaff[0].identity == 2
                      ? $store.state.mallCusStaff[0].phone
                      : $store.state.mallCusStaff[1].phone
                  }}
                </div>
              </div>
              <img
                class="icon"
                src="@/assets//images/mallCusStaff/phone.png"
                alt=""
              />
            </li>
            <!-- <li class="person-item">
              <div class="left">
                <div class="phone">座机:</div>
                <div class="number">18380455540</div>
              </div>
               <img class="icon" src="@/assets//images/mallCusStaff/call.png" alt="">
            </li>
            <li class="person-item">
              <div class="left">
                <div class="phone">微信:</div>
                <div class="number">18380455540</div>
              </div>
               <img class="icon" src="@/assets//images/mallCusStaff/wechat.png" alt="">
            </li>
            <li class="person-item">
              <div class="left">
                <div class="phone">QQ:</div>
                <div class="number">18380455540</div>
              </div>
               <img class="icon" src="@/assets//images/mallCusStaff/qq.png" alt="">
            </li> -->
          </ul>
        </div>
      </div>
      <div class="footer">欢迎使用云集药业医药采购平台</div>
    </div>
  </div>
</template>
 
<script>
import { boolean } from "mathjs";
export default {
  data() {
    return {
      firstDown: false,
    };
  },
  props: {
    isgoods: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    callFather() {
      this.$emit("claosePerson");
    },
  },
};
</script>
 
<style lang = "less" scoped>
@import "../../../assets/base.less";
* {
  margin: 0;
  padding: 0;
}
body,
html {
  width: 100%;
  height: 100%;
}
.warring {
  display: flex;
  align-items: center;
  height: 30px;
  box-sizing: border-box;
  padding: 18px;
  font-size: 12px;
  font-family: 'regular';
  font-weight: 400;
  color: #ffa936;
  background: #fff9f0;
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
}
.customer-serve {
  background: #fff;
  width: 230px;
  border: 2px solid #26c487;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border: 1px solid #f1f1f1;
    .el-icon-close {
      font-size: 14px;
    }
    .left {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .txt {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      font-family: 'medium';
      font-weight: 500;
      color: #333333;
    }
  }
  .mid {
    padding: 0 15px;
    .icon {
      width: 28px;
      height: 28px;
      background: #ffffff;
      border-radius: 50%;
      opacity: 1;
      margin-right: 6px;
    }
    .person {
      font-size: 14px;
      font-family: 'regular';
      font-weight: 400;
      color: #ffffff;
      font-size: 14px;
      font-family: 'regular';
      font-weight: 400;
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      width: 100%;
      height: 47px;
      background: linear-gradient(270deg, #39de9e 0%, #1be0b2 100%);
      border-radius: 6px;
      opacity: 1;
    }
    .list:nth-child(2) .person {
      background: linear-gradient(90deg, #559ffc 0%, #5388fe 100%);
    }
    .person-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      background: #f6f8fb;
      border-bottom: 1px solid #f1f1f1;
      .phone {
        font-size: 12px;
        font-family: 'regular';
        font-weight: 400;
        color: #999999;
      }
      .number {
        margin-top: 6px;
        font-size: 12px;
        font-family: 'regular';
        font-weight: 400;
        color: #333333;
      }
    }
    .person-item:last-child(1) {
      border-bottom: 0 solid #f1f1f1;
    }
  }
  .footer {
    margin-top: 20px;
    text-align: center;
    font-size: 12px;
    font-family: 'regular';
    font-weight: 400;
    color: #ffffff;
    padding: 8px 23px;
    background: #26c487;
  }
}
</style>