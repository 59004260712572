/*
 * @Description: description
 * @Author: booshaw
 * @Github: github
 * @Date: 2022-06-06 11:24:25
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-11-03 14:21:16
 */
import axios from "axios";
import { Loading, Message, MessageBox } from "element-ui";
import { saveAs } from "file-saver";
import { blobValidate, tansParams } from "@/utils/yuoyi";
import router from "@/router";
const instance = axios.create({
  // baseURL: "/api", // 发布环境
  baseURL: process.env.VUE_APP_BASE_URL, //开发环境
  // baseURL: 'https://apis.yunjiyy.cn/api', //生产环境
  // baseURL: 'https://yunjiyy.cn/api', //生产环境
  //baseURL:'http://10.8.0.105:6060/api',//刘金渝
  // baseURL:'http://10.8.0.113:6060/api',//刘水平
  // baseURL: 'http://172.16.100.60:30030/mock/21/api', //mock
  timeout: 30000,
});
// axios.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8'
//请求拦截器

instance.interceptors.request.use(
  (config) => {
    let token = sessionStorage.getItem("token");

    // get请求映射params参数
    if (config.method === "get" && config.params) {
      let url = config.url + "?" + tansParams(config.params);
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }
    // config.headers['Content-Type']='application/json;charset=UTF-8'

    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false;
    if (token && !isToken) {
      config.headers["Authorization"] = "bearer " + token; // 让每个请求携带自定义token 请根据实际情况自行修改
    }

    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

//响应拦截器
instance.interceptors.response.use(
  (res) => {
    if (res.data.code === 600) {
      router.replace({
        name: "Login",
      });
      Message.warning('请登录账号');
      return
    } else if (res.data.code == 401 || res.data.code === 603) {
      sessionStorage.removeItem("token");
      router.replace({
        name: "Login",
      });
      Message.error(res.data.msg);
      // 下载接口无code, 故接口拦截时先判断code是否存在
    }else if(res.data.code && res.data.code !== 200) {
      Message.error(res.data.msg);
    }
    return res.data;
  },
  (err) => {
    return Promise.reject(err);
  }
);

let downloadLoadingInstance;

// 通用下载方法
export function download(url, params, filename, timeout) {
  downloadLoadingInstance = Loading.service({
    text: "正在下载数据，请稍候",
    spinner: "el-icon-loading",
    background: "rgba(0, 0, 0, 0.7)",
  });
  return instance
    .post(url, params, {
      transformRequest: [
        (params) => {
          return tansParams(params);
        },
      ],
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      responseType: "arraybuffer",
      timeout: timeout || 30000,
    })
    .then(async (data) => {
      const isLogin = await blobValidate(data);
      if (isLogin) {
        const blob = new Blob([data]);
        saveAs(blob, filename);
      } else {
        const resText = await data.text();
        const rspObj = JSON.parse(resText);
        const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode["default"];
        Message.error(errMsg);
      }
      downloadLoadingInstance.close();
    })
    .catch((r) => {
      console.error(r);
      Message.error("下载文件出现错误，请联系管理员！");
      downloadLoadingInstance.close();
    });
}

export default instance;
